import { Fragment, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

import { BiError, BiMinusCircle } from "react-icons/bi";
import "../Styles/resume.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";

import ImgFooter from "../Components/Resume/Footer";
import Header from "../Components/Resume/Header";
import NameAndPos from "../Components/Resume/NameAndPos";
import Contact from "../Components/Resume/Contact";
import SkillCert from "../Components/Resume/SkillCert";
import WorkExperience from "../Components/Resume/WorkExperience";
import Education from "../Components/Resume/Education";
import ProfilePic from "../Components/Resume/ProfilePic";
import NotFound from "./NotFound";

import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";

function Resume() {
  const { token } = useParams();
  let navigate = useNavigate();
  const [resumeUserId, setResumeUserId] = useState();
  const [validAccess, setValidAccess] = useState(false);

  const printRef = useRef();
  const generatePDF = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "resume_",
  });
  // const generatePDF = () => {

  //   // const report = new JsPDF("portrait", "pt", "A2");
  //   // report.html(document.querySelector("#resume")).then(() => {
  //   //   report.save("report.pdf");
  //   // });

  //   const input = document.getElementById("resume");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new JsPDF("landscape", "pt", "A3");
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     // pdf.output('dataurlnewwindow');
  //     pdf.save("download.pdf");
  //   });
  // };

  useEffect(() => {
    //Decrypt
    if (localStorage.getItem("accessToken")) {
      var bytes = CryptoJS.AES.decrypt(
        token,
        `${process.env.REACT_APP_ENCRYPT_CODE}`
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setResumeUserId(parseInt(decryptedData));

      Axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/resume/access`,
        {
          resumeUserId: resumeUserId,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((response) => {
        if (response.data.success) {
          setValidAccess(true);
        } else if (response.data.warn) {
          return toast.warn(
            <Fragment>
              <BiError />
              <span>{response.data.warn}</span>
            </Fragment>
          );
        } else {
          return toast.error(
            <Fragment>
              <BiMinusCircle />
              <span>{response.data.error}</span>
            </Fragment>
          );
        }
      });
    } else {
      return [
        navigate("/signup"),
        toast.warn(
          <Fragment>
            <BiMinusCircle />
            <span>Please Sign In /Sign Up before view resume</span>
          </Fragment>
        ),
      ];
    }
  }, [resumeUserId]);

  return (
    <>
      {validAccess ? (
        <div className="resume mb-5">
          {resumeUserId && (
            <>
              <Header />
              <div className="d-flex justify-content-end mb-3 mx-5">
                <Button className="" onClick={generatePDF}>
                  Export as PDF
                </Button>
              </div>
              <Container
                className="border rounded"
                id="resume"
                ref={printRef}
                style={{ width: "100%" }}
              >
                <div className="p-4">
                  <Row className="justify-content-center pb-3 border-bottom ">
                    <Col lg={3} className="mx-0 py-3">
                      <ProfilePic id={resumeUserId} />
                    </Col>
                    <Col lg={6}>
                      <NameAndPos id={resumeUserId} />
                    </Col>
                    <Col lg={3}>
                      <Contact id={resumeUserId} />
                    </Col>
                  </Row>
                  <Row className="row d-flex justify-content-md-center pt-3">
                    <Col lg={9} className="pt-3 mx-0">
                      <WorkExperience id={resumeUserId} />
                      <Education id={resumeUserId} />
                    </Col>
                    <Col lg={3}>
                      <SkillCert id={resumeUserId} />
                    </Col>
                  </Row>
                </div>
              </Container>
              <ImgFooter />
              <ScrollToTop />
              <Footer />
            </>
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default Resume;
