import {useState , useEffect} from 'react'
import {BiBookBookmark} from 'react-icons/bi'
import Axios from 'axios'

const Education = (props) => {
  const [educations, setEducations] = useState()
  useEffect(()=>{
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/educations/`+props.id)
    .then(res=>{
      setEducations(res.data)
  })
  },[])
  return (
    <div>
        {educations && <><div className='d-flex my-2'>
            <span className="border rounded text-light px-2 pb-2 my-auto py-1 bg-dark" id="workIcon">
            <BiBookBookmark/>
            </span>
            
        <h3 className="px-2" id="catTitle">EDUCATION</h3>
        </div>
        {/* <div className="py-3">
            <p className="my-0"><h4>Bachelor of Computer Science in Software Engineering</h4></p>
            <p className="my-0"><h6>University of Malaya</h6></p>
            <p className="text-warning">2011-2014</p>
            
        </div> */}
        <div className="p-3">
        {educations.map((education,key)=>
        {
          const higherStud = education.higher_stud
          const studyField = education.study_field
          const school = education.school
          const gradYearFrom = education.grad_year_from
          const gradYearTo = education.grad_year_to
          return (
            <div className="py-2" key={key}>
            <p className="my-0"><h4>{higherStud} in {studyField}</h4></p>
            <p className="my-0"><h6>{school}</h6></p>
            <p className="text-warning">{gradYearFrom} - {gradYearTo}</p>
            </div>
          )
        }
        )}
        </div>
        </>}
        
    </div>
  )
}

export default Education