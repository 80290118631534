import React, { useState, useEffect } from "react";
import Axios from "axios";

import CoverImg from "../Assests/Images/background/cover.png";

function Banner(props) {
  const userID = props.id;
  //console.log(userID);
  /* const design = props.design;
  const alt = props.alt; */
  /*   const width = props.width;
  const height = props.height; */

  //rerender pfp
  //const avatar = props.renderAvatar;

  //Banner picture state
  const [viewBanner, setViewProfile] = useState(CoverImg);

  // useEffect(() => {
  //   Axios.get(
  //     `${process.env.REACT_APP_SERVER_DOMAIN}/userDashboard/user__Banner/` +
  //       userID
  //   ).then((response) => {
  //     const bannerImage = response.data.data[0][0].banner_image;
  //     console.log(response.data.success);
  //     console.log(response.data.data[0][0].banner_image);

  //     let profile;
      
  //     //
  //     if (bannerImage.length > 0) {
  //       profile =
  //         `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/banner/user-banner/` +
  //         bannerImage;
  //       setViewProfile(profile);
  //       console.log(profile);
  //     }
  //   });
  // }, [userID]);

  return (
    <div>
      <img src={viewBanner} className={props.design} alt={props.alt} />{" "}
    </div>
  );
}

export default Banner;
