import React, { useEffect, useState } from "react";
import Axios from "axios";
import CryptoJS from "crypto-js";

import { Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BiTime,
  BiXCircle,
  BiCheckCircle,
  BiMinusCircle,
  BiErrorCircle,
  BiChevronsRight,
} from "react-icons/bi";
import briefcaseIcon from "../../Assests/Images/icon/briefcase.svg";
import Ads from "../Ads";

import Reschedule from "../Interview/User/Reschedule";
import Profile from "../Profile";
import Name from "../Name";
import Heading from "../Heading";

function Employer() {
  const [pendingNum, setPendingNum] = useState(0);
  const [shortlistedNum, setShortlistedNum] = useState(0);
  const [hiredNum, setHiredNum] = useState(0);
  const [rejectNum, setRejectNum] = useState(0);
  const [applicationList, setApplicationList] = useState([]);
  const [interviewList, setInterviewList] = useState([]);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/employerApplication/list`,
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      setApplicationList(response.data);
    });
  }, []);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboard/jobapplicationNum`,
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      setPendingNum(response.data[0][0].pendingNum);
      setRejectNum(response.data[1][0].rejectNum);
      setHiredNum(response.data[2][0].hiredNum);
      setShortlistedNum(response.data[3][0].shortlistedNum);
    });
  }, []);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/employerInterview/list`, {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    }).then((response) => {
      setInterviewList(response.data);
    });
  }, []);

  return (
    <Row>
      <Col lg={10} md={12} sm={12} className="mx-auto my-3 p-5">
        <Col lg={12} md={12} sm={12} className="mx-auto my-3">
          <Row className="m-1 text-center text-light">
            <Col className="border border-0 rounded bg-warning mx-1 p-2">
              <h3>{pendingNum}</h3>
              <h6>
                <span className="d-none d-sm-block">Application</span> Pending
              </h6>
            </Col>
            <Col className="border border-0 rounded bg-primary mx-1 p-2">
              <h3>{shortlistedNum}</h3>
              <h6>
                <span className="d-none d-sm-block">Application</span>{" "}
                Shortlisted
              </h6>
            </Col>
            <Col className="border border-0 rounded bg-danger mx-1 p-2">
              <h3>{rejectNum}</h3>
              <h6>
                <span className="d-none d-sm-block">Application</span> Rejected
              </h6>
            </Col>
            <Col className="border border-0 rounded bg-success mx-1 p-2">
              <h3>{hiredNum}</h3>
              <h6>
                <span className="d-none d-sm-block">Application</span> Hired
              </h6>
            </Col>
          </Row>
        </Col>

        <Col
          lg={12}
          md={12}
          sm={12}
          className="mx-auto my-3 border rounded p-2"
        >
          <Heading content="Latest Applicant" design="h5 pb-1 border-bottom" />
          <Row>
            {applicationList.slice(0, 3).map((applicationDetails, key) => {
              const applicationID = applicationDetails.grab_oppo_id;
              const jobTitle = applicationDetails.job_title;
              const candidateID = applicationDetails.candidate_user_id;
              const jobType = applicationDetails.job_type;
              const applicationStatus = applicationDetails.approve_candidate;
              const withdrawReason = applicationDetails.withdrawReason;

              let jobTypeBG;
              switch (jobType) {
                case "Contract":
                  jobTypeBG = "bg-contract";
                  break;
                case "Internship":
                  jobTypeBG = "bg-internship";
                  break;
                case "Part Time":
                  jobTypeBG = "bg-partTime";
                  break;
                default:
                  jobTypeBG = "bg-fullTime";
              }

              let status;
              let statusBG;
              let statusIcon;
              switch (applicationStatus) {
                case 4:
                  status = "Withdrew";
                  statusBG = "link-danger";
                  statusIcon = <BiMinusCircle className="icon" />;
                  break;
                case 3:
                  status = "Shortlisted";
                  statusBG = "link-warning";
                  statusIcon = <BiTime className="icon" />;
                  break;
                case 2:
                  status = "Accepted";
                  statusBG = "link-success";
                  statusIcon = <BiCheckCircle className="icon" />;
                  break;
                case 1:
                  status = "Rejected";
                  statusBG = "link-danger";
                  statusIcon = <BiXCircle className="icon" />;
                  break;
                default:
                  status = "Pending";
                  statusBG = "link-warning";
                  statusIcon = <BiTime className="icon" />;
              }

              return (
                <>
                  <Row className="mx-auto my-3 p-3 border-bottom">
                    <Col sm={2} className="d-none d-md-none d-lg-block">
                      <Profile
                        id={candidateID}
                        design="rounded-circle"
                        width="100"
                        height="100"
                      />
                    </Col>
                    <Col xs={12} sm={8}>
                      <p className="m-0">{jobTitle}</p>
                      <p className="m-0">
                        <a
                          target="_blank"
                          href={`/resume/${encodeURIComponent(
                            CryptoJS.AES.encrypt(
                              String(candidateID),
                              `${process.env.REACT_APP_ENCRYPT_CODE}`
                            )
                          ).toString()}`}
                          className="text-dark text-decoration-none"
                        >
                          <Name id={candidateID} />
                        </a>
                      </p>
                      <p>
                        <span
                          className={`badge rounded-pill text-dark ${jobTypeBG}`}
                        >
                          <img
                            alt="briefcaseIcon"
                            className="icon"
                            src={briefcaseIcon}
                          />
                          {jobType}
                        </span>
                      </p>
                      {status === "Withdrew" && (
                        <Alert variant="danger">
                          <BiErrorCircle /> Withdrew Reason: {withdrawReason}
                        </Alert>
                      )}
                    </Col>
                    <Col sm={2} className="text-center">
                      <p className={statusBG}>
                        {" "}
                        {statusIcon} {status}
                      </p>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Row>
          <Row>
            <Col sm={12} className="text-end fs-6">
              <p className="m-0" role="button">
                <Link to="/application">
                  View More <BiChevronsRight />
                </Link>
              </p>
            </Col>
          </Row>
        </Col>

        <Col
          lg={12}
          md={12}
          sm={12}
          className="mx-auto my-3 border rounded p-2"
        >
          <Heading
            content="Upcoming Interview"
            design="h5 pb-1 border-bottom"
          />
          <Row>
            {interviewList.slice(0, 2).map((interviewDetails, key) => {
              const interviewID = interviewDetails.interviewID;
              const candidateID = interviewDetails.candidate_id;
              const grabID = interviewDetails.oppo_id;
              const jobTitle = interviewDetails.job_title;
              const interviewerName = interviewDetails.interviewerName;
              const interviewRemarks = interviewDetails.interviewRemarks;
              const interviewLink = interviewDetails.interviewVenueOrVideo;
              const interviewingTime = interviewDetails.interviewTime;
              let interviewingDate = new Date(interviewDetails.interviewDate);
              const offset = interviewingDate.getTimezoneOffset();
              //to calculate malaysia time
              interviewingDate = new Date(
                interviewingDate.getTime() - offset * 60 * 1000
              );
              interviewingDate = interviewingDate.toISOString().split("T")[0];

              // const interviewTimeConvert = interviewingTime
              //   .toLocaleDateString("en-GB", {
              //     day: "numeric",
              //     month: "short",
              //     year: "numeric",
              //     hour: "2-digit",
              //     minute: "2-digit",
              //     hour12: true,
              //   })
              //   .replace(/ /g, " ");

              // const interviewInitial = interviewingTime
              //   .toISOString()
              //   .replace(".000Z", "");

              return (
                <Row className="mx-auto my-3 p-3 border-bottom">
                  <Col sm={2} className="d-none d-md-none d-lg-block">
                    <Profile
                      id={candidateID}
                      design="rounded-circle"
                      width="100"
                      height="100"
                    />
                  </Col>
                  <Col xs={12} sm={8}>
                    <div>
                      <p className="m-0 fw-bold">{jobTitle}</p>
                      <p className="m-0">
                        <Name id={candidateID} />
                      </p>
                      <p className="m-0">Interviewer Name: {interviewerName}</p>
                      <p className="m-0">
                        <BiTime className="icon" />
                        {interviewingTime} {interviewingDate}
                      </p>
                      <a
                        href={interviewLink}
                        target="_blank"
                        rel="noreferrer"
                        className="m-0 text-decoration-none"
                      >
                        {interviewLink}
                      </a>
                      <p>Remarks: {interviewRemarks}</p>
                    </div>
                    <Reschedule
                      interviewID={interviewID}
                      interviewerName={interviewerName}
                      interviewLink={interviewLink}
                    />
                  </Col>
                </Row>
              );
            })}
          </Row>
          <Row>
            <Col sm={12} className="text-end fs-6">
              <p className="m-0" role="button">
                <Link to="/interview">
                  View More <BiChevronsRight />
                </Link>
              </p>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col lg={2} md={12} sm={12} className="p-4">
        <Ads />
      </Col>
    </Row>
  );
}

export default Employer;
