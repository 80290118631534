import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import OpportunityLongCard from '../Opportunity/OpportunityLongCard';
const SavedApplication = () => {

    const [savedList, setSavedList] = useState([]);

    useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      Axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/userApplication/savedList`,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((response) => {
        setSavedList(response.data)
        console.log(response.data);
      })
    }
    },[]);

  return (
    <div>{savedList.map((opportunityDetails)=>{
        const oppoID = opportunityDetails.oppo_id;
        const job_type = opportunityDetails.job_type;
        const job_title = opportunityDetails.job_title;
        const company_name = opportunityDetails.companyname;
        const job_location = opportunityDetails.job_location;
        const salary_status = opportunityDetails.salary_status;
        const salary = opportunityDetails.salary;
        const test = opportunityDetails.test;
        const nationality = opportunityDetails.nationality;
        const year_exp = opportunityDetails.year_exp;
        const fresh_graduate = opportunityDetails.fresh_graduate;
        const education = opportunityDetails.education;
        const field_study = opportunityDetails.field_study;
        const iv_option = opportunityDetails.iv_option;
        const job_desc = opportunityDetails.job_desc;
        const partnerLink = "/partner/" + opportunityDetails.companyname.toLowerCase().trim().split(/\s+/).join('-');
        const partnerLogo = "https://www.zom-in.com/images/profileimg/" + opportunityDetails.company_logo;
        return (
            <OpportunityLongCard 
                key={oppoID}
                oppoID={oppoID}
                cardImage={partnerLogo}
                jobType={job_type}
                jobTitle={job_title}
                jobLocation={job_location}
                compName={company_name}
                compLink={partnerLink}
                salary={salary}
                salaryStatus={salary_status}
                jobTest={test}
                nationality={nationality}
                yearExp={year_exp}
                freshGraduate={fresh_graduate}
                education={education}
                fieldStudy={field_study}
                ivOption={iv_option}
                jobDesc={job_desc}
            />
        )
    })}
    </div>
    
  )
}

export default SavedApplication