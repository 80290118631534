import {useState, useEffect} from 'react'
import {FaLanguage } from 'react-icons/fa'
import Axios from 'axios'

const Languages = (props) => {
  const [languages,setLanguages] = useState()
  useEffect(()=>{
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/languages/`+props.id)
    .then(res=>{
        setLanguages(res.data)
    })
},[])
  return (
    <>{languages && <div className="pb-3">
        <div className='d-flex mb-4 mt-2'>
            <span className="border rounded bg-white text-dark px-1 pb-1">
            <FaLanguage />
            </span>
            
        <h5 className="px-2">
        LANGUAGES
        </h5>
        </div>
        {languages.map((language,key)=>
          {
            const eachLanguage = language.language
            return(
              <p key={key}><span className="bg-secondary rounded px-2 py-1">{eachLanguage}</span></p>
            )
          }
        )}
        
    </div>}
    </>
  )
}

export default Languages