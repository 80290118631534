import Axios from 'axios';
import React, { useEffect, useState , Fragment} from 'react'

import { Button , Modal } from "react-bootstrap";
import Heading from "../Heading";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";
//Toast
import { toast } from "react-toastify";

const ApplicationModal = (props) => {
    const [titleHeading, setTitleHeading] = useState();
    
    const onClickUnderstood = () =>{
        props.setShow(false);
        console.log(props.applicationID)
        Axios.patch(`${process.env.REACT_APP_SERVER_DOMAIN}/employerApplication/updateStatus`,
        {
          status: props.status,
          applicationID: props.applicationID
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }).then((response)=>{
          if (response.data.error) {
            return toast.error(
              <Fragment>
                <BiMinusCircle /> <span>{response.data.error}</span>
              </Fragment>
            );
          } else if (response.data.warn) {
            return toast.warn(
              <Fragment>
                <BiError />
                <span>{response.data.warn}</span>
              </Fragment>
            );
          } else {
            return toast.success(
              <Fragment>
                <BiMessageCheck />
                <span>{response.data.success}</span>
              </Fragment>
            );
          }
        })
    }

    useEffect(()=>{
        if(props.status=="Shortlisted"){
            setTitleHeading("Shortlisting");
        }else if(props.status=="Hired"){
            setTitleHeading("Hiring");
        }else if(props.status=="Rejected"){
            setTitleHeading("Rejecting")
        }
    },[props.status])
    
  return (
    <Modal
                show={props.show}
                onHide={() => props.setShow(false)}
                scrollable
                size="md"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <Heading content={`${titleHeading} the candidate`} design="h4" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Once you choose "{props.status}"{props.applicationID}, an email will be sent to notify the
                  candidate. Do you want to proceed?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => props.setShow(false)}
                  >
                    Close
                  </Button>
                  <Button variant="primary"
                    onClick={onClickUnderstood}>
                      Understood</Button>
                </Modal.Footer>
              </Modal>
  )
}

export default ApplicationModal