import { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import { BsPencilSquare } from "react-icons/bs";
import { Button, Modal, Form } from "react-bootstrap";
import { BiMessageCheck, BiMinusCircle } from "react-icons/bi";
import { toast } from "react-toastify";

const EditName = ({ id }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState();
  console.log(name);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboard/username/` + id
    ).then((response) => {
      const userDetails = response.data[0];
      setName(userDetails.name);
    });
  }, [id]);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const handleSubmit = () => {
    Axios.patch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboardUserProfile/name`,
      {
        id: id,
        name: name,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        return toast.success(
          <Fragment>
            <BiMessageCheck />
            <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });
    setShow(false);
    setTimeout(window.location.reload(), 3000);
  };
  return (
    <div className="mx-2">
      {/* <Name id={id} /> */}
      <div onClick={() => setShow(true)}>
        <BsPencilSquare />
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={name === "" ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditName;
