import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import LatestPos from './LatestPos';

const NameAndPos = (props) => {
  const [user,setUser] = useState();
  useEffect(()=>{
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/name/`+props.id)
    .then(res=>{
      setUser(res.data[0])
    })
  },[])
  return (
    <div className="py-2">
        {user && <h1 className="text-muted">{user.name}</h1>}
        <h2 className="text-warning text-monospace"><LatestPos id={props.id}/></h2>
        {user &&<p>
        {user.about_user}
        </p>}
    </div>
  )
}

export default NameAndPos