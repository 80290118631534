import { useState , useEffect } from 'react'
import Axios from 'axios'

const LatestPos = (props) => {
    const [pos,setPos] = useState();
    useEffect(()=>{
        Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/position/`+props.id)
        .then(res=>{
          setPos(res.data[0])
        })
      },[])
  return (
    <span>{pos && pos.cur_position}</span>
  )
}

export default LatestPos