import React, { useEffect, useState, Fragment } from "react";
import Axios from "axios";

import { Row, Col, Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";

//Toast
import { toast } from "react-toastify";

import useInput from "../../Helpers/use-input";

const isNotEmpty = (value) => value.trim() !== "";

function FullTime({ setShow }) {
  const [location, setlocation] = useState([]);
  const [eduList, setEduList] = useState([]);
  const [salaryStatus, setSalaryStatus] = useState(false);
  const [freshGraduate, setFreshGraduate] = useState(false);
  const [testConduct, setTestConduct] = useState(false);

  //Location
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/stateLocation`).then(
      (response) => {
        setlocation(response.data);
      }
    );
  }, []);

  //Education Level
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/eduLevel`).then(
      (response) => {
        setEduList(response.data);
      }
    );
  }, []);

  const createJobHandler = () => {
    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/opportunity/jobOpportunity/fullTime`,
      {
        jobType: "Full Time",
        jobTitle: enteredJobTitle,
        salary: enteredSalary,
        salaryStatus: salaryStatus,
        location: enteredLocation,
        expYear: enteredExpYear,
        freshGraduate: freshGraduate,
        education: enteredHighLevel,
        studyField: enteredStudyField,
        nationality: enteredNationality,
        interviewOption: enteredInterviewOption,
        test: testConduct,
        jobDesc: jobDesc,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else if (response.data.warn) {
        return toast.warn(
          <Fragment>
            <BiError />
            <span>{response.data.warn}</span>
          </Fragment>
        );
      } else {
        return toast.success(
          <Fragment>
            <BiMessageCheck />
            <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });
    setShow(false);
  };

  //JobTitle Input Check
  const {
    value: enteredJobTitle,
    isValid: enteredJobTitleIsValid,
    hasError: jobTitleInputHasError,
    valueChangeHandler: jobTitleChangeHandler,
    inputBlurHandler: jobTitleBlurHandler,
    reset: resetjobTitle,
  } = useInput(isNotEmpty);

  //Salary Input Check
  const {
    value: enteredSalary,
    isValid: enteredSalaryIsValid,
    hasError: salaryInputHasError,
    valueChangeHandler: salaryChangeHandler,
    inputBlurHandler: salaryBlurHandler,
    reset: resetSalary,
  } = useInput(isNotEmpty);

  //Location Input Check
  const {
    value: enteredLocation,
    isValid: enteredLocationIsValid,
    hasError: locationInputHasError,
    valueChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    reset: resetLocation,
  } = useInput(isNotEmpty);

  //Experience Year Input Check
  const {
    value: enteredExpYear,
    isValid: enteredExpYearIsValid,
    hasError: expYearInputHasError,
    valueChangeHandler: expYearChangeHandler,
    inputBlurHandler: expYearBlurHandler,
    reset: resetExpYear,
  } = useInput(isNotEmpty);

  //Edu Level Input Check
  const {
    value: enteredHighLevel,
    isValid: enteredHighLevelIsValid,
    hasError: highLevelInputHasError,
    valueChangeHandler: highLevelChangeHandler,
    inputBlurHandler: highLevelBlurHandler,
    reset: resetHighLevel,
  } = useInput(isNotEmpty);

  //Study Field Input Check
  const {
    value: enteredStudyField,
    isValid: enteredStudyFieldIsValid,
    hasError: studyFieldInputHasError,
    valueChangeHandler: studyFieldChangeHandler,
    inputBlurHandler: studyFieldBlurHandler,
    reset: resetStudyField,
  } = useInput(isNotEmpty);

  //Nationality Input Check
  const {
    value: enteredNationality,
    isValid: enteredNationalityIsValid,
    hasError: nationalityInputHasError,
    valueChangeHandler: nationalityChangeHandler,
    inputBlurHandler: nationalityBlurHandler,
    reset: resetNationality,
  } = useInput(isNotEmpty);

  //Interview Option Input Check
  const {
    value: enteredInterviewOption,
    isValid: enteredInterviewOptionIsValid,
    hasError: interviewOptionHasError,
    valueChangeHandler: interviewOptionChangeHandler,
    inputBlurHandler: interviewOptionBlurHandler,
    reset: resetInterviewOption,
  } = useInput(isNotEmpty);

  const [jobDesc, setJobDesc] = useState("");

  let createJobIsValid = false;

  if (
    enteredJobTitle &&
    enteredSalary &&
    enteredLocation &&
    enteredExpYear &&
    enteredHighLevel &&
    enteredStudyField &&
    enteredNationality &&
    enteredInterviewOption
  ) {
    createJobIsValid = true;
  }

  const jobTitleClasses = jobTitleInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const salaryClasses = salaryInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const locationClasses = locationInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const expYearClasses = expYearInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const highLevelClasses = highLevelInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const studyFieldClasses = studyFieldInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const nationalityClasses = nationalityInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const interviewOptionClasses = interviewOptionHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  return (
    <Row className="my-2">
      <Form>
        <Col sm={12} className="form-group mb-3">
          <label htmlFor="jobTitle" className="required">
            Job Title
          </label>
          <input
            type="text"
            placeholder="Enter your Job Title"
            autoComplete="off"
            //className="form-control form-control-sm"
            className={jobTitleClasses}
            onChange={jobTitleChangeHandler}
            onBlur={jobTitleBlurHandler}
            value={enteredJobTitle}
          />
          {/* <p className="fs-7 text-danger">
            * Make sure the Job title is correct and it is not editable{" "}
          </p> */}

          {jobTitleInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please fill in the job title.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-3">
          <label htmlFor="salary" className="required">
            Salary
          </label>
          <select
            aria-label="Salary"
            className={salaryClasses}
            value={enteredSalary}
            onChange={salaryChangeHandler}
            onBlur={salaryBlurHandler}
          >
            <option value="">Please Choose One</option>
            <option value="RM 1000 and Below">RM 1000 and Below</option>
            <option value="RM 1001 to RM 2000">RM 1001 to RM 2000</option>
            <option value="RM 2001 and RM 3000">RM 2001 and RM 3000</option>
            <option value="RM 3001 and RM 4000">RM 3001 and RM 4000</option>
            <option value="RM 4001 and RM 5000">RM 4001 and RM 5000</option>
            <option value="RM 5001 and Above">RM 5001 and Above</option>
          </select>
          <p>
            <input
              type="checkbox"
              name="salary_status"
              value="0"
              onChange={() => setSalaryStatus(!salaryStatus)}
            />{" "}
            Do not disclose salary range to job seeker.
          </p>

          {salaryInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please choose the salary range.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-2">
          <label htmlFor="location" className="required">
            Location
          </label>
          <select
            defaultValue={""}
            aria-label="Location"
            className={locationClasses}
            onChange={locationChangeHandler}
            onBlur={locationBlurHandler}
            value={enteredLocation}
          >
            <option value="">Please Choose One</option>
            {location.map((location) => {
              const enteredLocation = location.child_name;
              const enteredLocationID = location.child_id;
              return (
                <option key={enteredLocationID} value={enteredLocation}>
                  {enteredLocation}
                </option>
              );
            })}
          </select>
          {locationInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please choose your location.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-3">
          <label htmlFor="expYear" className="required">
            Experience in years
          </label>
          <input
            type="number"
            min="0"
            max="30"
            placeholder="Enter experience in years"
            //className="form-control form-control-sm"
            className={expYearClasses}
            onChange={expYearChangeHandler}
            onBlur={expYearBlurHandler}
            value={enteredExpYear}
          />
          <p>
            <input
              type="checkbox"
              name="freshGraduate"
              value="0"
              onChange={() => setFreshGraduate(!freshGraduate)}
            />{" "}
            Open for Fresh Graduates.
          </p>
          {expYearInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please fill in the experience.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-2">
          <label htmlFor="eduLevel" className="required">
            Minimum Education Level
          </label>
          <select
            aria-label="Education Level"
            className={highLevelClasses}
            onChange={highLevelChangeHandler}
            onBlur={highLevelBlurHandler}
            value={enteredHighLevel}
          >
            <option value="">Please Choose One</option>
            {eduList.map((eduList, key) => {
              const enteredHighLevel = eduList.child_name;
              return (
                <option key={enteredHighLevel} value={enteredHighLevel}>
                  {enteredHighLevel}
                </option>
              );
            })}
          </select>

          {highLevelInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please choose your minimum education Level.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-2">
          <label htmlFor="studyField" className="required">
            Field of Study
          </label>
          <input
            type="text"
            placeholder="Enter Field of Study"
            className={studyFieldClasses}
            onChange={studyFieldChangeHandler}
            onBlur={studyFieldBlurHandler}
            value={enteredStudyField}
          />

          {studyFieldInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please fill in your field of study.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-3">
          <label htmlFor="nationality" className="required">
            Nationality
          </label>
          <select
            defaultValue={""}
            aria-label="Nationality"
            className={nationalityClasses}
            onChange={nationalityChangeHandler}
            onBlur={nationalityBlurHandler}
            value={enteredNationality}
          >
            <option value="">Please Choose One</option>
            <option value="Malaysian only">Malaysian Only</option>
            <option value="Not specific">Not specific</option>
          </select>

          {nationalityInputHasError && (
            <Form.Control.Feedback type="invalid">
              Please Choose One.
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-3">
          <label htmlFor="interviewOption" className="required">
            Interview Option
          </label>
          <select
            defaultValue={""}
            aria-label="InterviewOption"
            className={interviewOptionClasses}
            onChange={interviewOptionChangeHandler}
            onBlur={interviewOptionBlurHandler}
            value={enteredInterviewOption}
          >
            <option value="">Please Choose One</option>
            <option value="Face to Face">Face to Face</option>
            <option value="Video Call">Video Call</option>
            <option value="Face to Face / Video Call">
              Face to Face / Video Call
            </option>
          </select>
          <p>
            <input
              type="checkbox"
              name="testConduct"
              value="0"
              onChange={() => setTestConduct(!testConduct)}
            />{" "}
            Test / Assessment will be conducted.
          </p>
          {interviewOptionHasError && (
            <Form.Control.Feedback type="invalid">
              Please Choose One
            </Form.Control.Feedback>
          )}
        </Col>

        <Col sm={12} className="form-group mb-2">
          <label htmlFor="experience" className="required">
            Job Requirement / Responsibility
          </label>
          <CKEditor
            editor={ClassicEditor}
            config={{
              placeholder: "Job Requirement / Responsibility",
              removePlugins: [
                "Heading",
                // // "Essentials",
                // // "Italic",
                // "Bold",
                // "Link",
                "imageUpload",
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "BlockQuote",
                // // "List",
                "Indent",
                "Table",
              ],
            }}
            data={jobDesc}
            onChange={(event, editor) => {
              const data = editor.getData();
              setJobDesc(data);
            }}
          />
        </Col>
        <Col className="border-top py-2">
          <Button
            className="float-end"
            variant="primary"
            onClick={createJobHandler}
            disabled={!createJobIsValid}
          >
            Create Job
          </Button>
          <Button
            className="float-end mx-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default FullTime;
