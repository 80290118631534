import React from 'react'
import Accomplishment from './Accomplishment'
import Languages from './Languages'
import Skills from './Skills'

const SkillCert = (props) => {
  return (
    <div className="border rounded px-3 text-white h-100 bg-dark py-3">
        <Skills id={props.id} />
        <Languages id={props.id}/>
        <Accomplishment id={props.id}/>
    </div>
  )
}

export default SkillCert