import React, {/*  useState, */ Fragment } from "react";
import Axios from "axios";
import { Col, Form } from "react-bootstrap";
import { BiChevronLeft } from "react-icons/bi";
//import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";

import Heading from "../Heading";

import useInput from "../../Helpers/use-input";
import { toast } from "react-toastify";

function UserName({ setshowSubContentClick }) {
  /* const [currentPassword, setCurrentPassword] = useState(false); */

  /* const toggleCurrentPassword = () => {
    setCurrentPassword(!currentPassword);
  }; */

  // Current Password Input Check
  const {
    value: enteredCurrentPassword,
    isValid: enteredCurrentPasswordIsValid,
    hasError: currentPasswordInputHasError,
    valueChangeHandler: currentPasswordChangeHandler,
    inputBlurHandler: currentPasswordBlurHandler,
    reset: resetCurrentPasswordInput,
  } = useInput();

  let changePasswordIsValid = false;

  if (enteredCurrentPassword) {
    changePasswordIsValid = true;
  }

  const changePasswordFormSubmissionHandler = (event) => {
    event.preventDefault();

    if (!enteredCurrentPasswordIsValid) {
      return;
    } else {
      Axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/settings/username`,
        {
          userName: enteredCurrentPassword,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((response) => {
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiMinusCircle /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else if (response.data.warn) {
          return toast.warn(
            <Fragment>
              <BiError /> <span>{response.data.warn}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      });
    }
    resetCurrentPasswordInput();
  };

  const currentPasswordClasses = currentPasswordInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  return (
    <div>
      <h1
        onClick={() => setshowSubContentClick(0)}
        className="me-2 d-block d-sm-none"
      >
        <BiChevronLeft className="d-inline" />
        <Heading content="Password" design="h1 d-inline-block" />
      </h1>
      <Col lg={10} md={12} sm={12} className="mx-auto my-2">
        <Heading content="Change User Name" design=" h3 mb-4" />

        <Form onSubmit={changePasswordFormSubmissionHandler}>
          <Col sm={12} className="form-group mb-3">
            <label htmlFor="studyField" className="required">
              New user name
            </label>
            <div className="input-group mb-3">
              <input
                /* type={currentPassword ? "text" : "password"} */
                type="text"
                className={currentPasswordClasses}
                onChange={currentPasswordChangeHandler}
                onBlur={currentPasswordBlurHandler}
                value={enteredCurrentPassword}
              />
              {/* <span
                className="input-group-text"
                onClick={toggleCurrentPassword}
              >
                {currentPassword === true ? <BsEyeFill /> : <BsEyeSlashFill />}
              </span> */}
              {currentPasswordInputHasError && (
                <Form.Control.Feedback type="invalid">
                  Enter your new user name
                </Form.Control.Feedback>
              )}
            </div>
          </Col>

          <Col className="text-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!changePasswordIsValid}
            >
              Save
            </button>
          </Col>
        </Form>
      </Col>
    </div>
  );
}

export default UserName;
