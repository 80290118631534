import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";

import OpportunityShortCard from "../Opportunity/OpportunityShortCard";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import Benefit1 from "../../Assests/Images/index/zom-in-benefit-1.png";
import Benefit2 from "../../Assests/Images/index/zom-in-benefit-2.png";
import Benefit3 from "../../Assests/Images/index/zom-in-benefit-3.png";
import InstantHire from "../../Assests/Images/visuals/1.png";
import step1 from "../../Assests/Images/visuals/2.png";
import step2 from "../../Assests/Images/visuals/3.png";
import step3 from "../../Assests/Images/visuals/4.png";
import door1 from "../../Assests/Images/visuals/5.png";
import door2 from "../../Assests/Images/visuals/6.png";
import door3 from "../../Assests/Images/visuals/7.png";
import { MdArrowOutward } from "react-icons/md";

function Main() {
  let navigate = useNavigate();
  const [opportunityList, setOpportunityList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/opportunity/list`).then(
      (response) => {
        setOpportunityList(response.data);
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/partner/list`).then(
      (response) => {
        setPartnerList(response.data);
      }
    );
  }, []);

  const lookforJob = () => {
    navigate("/opportunity");
  };

  /* form links */
  const googleForm1 =
    "https://docs.google.com/forms/d/e/1FAIpQLSdAy7pcDifEp_pTYVvy6jV6vmLtUDPr1BKpiV4N-t0RrdJz5A/viewform";
  const googleForm2 =
    "https://docs.google.com/forms/d/e/1FAIpQLSeT_sswJpptT0S4p5BqmCYlnQ56TFYzK_GmRCkkDvYrIgB7Dw/viewform";
  const googleForm3 =
    "https://docs.google.com/forms/d/e/1FAIpQLSe7EEWaHIJD5J-pObCS5eJ4cp-vwyOzgjo0bAXF45612X2Fog/viewform";

  return (
    <div className="index indexPage mb-5" id="index">
      <Container className="py-5">
        <Row className="position-relative imgBox mb-5">
          <Col
            lg={5}
            md={9}
            sm={10}
            className="px-lg-4 px-md-2 pb-3 position-absolute top-50 start-50 translate-middle"
          >
            <div className="input-group">
              <input
                className="form-control py-1 px-2"
                type="search"
                placeholder="Search the job you are looking for"
                onChange={lookforJob}
              />
              <button
                className="btn btn-outline-info"
                type="button"
                onClick={lookforJob}
              >
                <BiSearchAlt /> Search
              </button>
            </div>
          </Col>
        </Row>

        {/* Get Hired Instantly */}
        <Row className="my-5 mx-auto d-flex justify-content-around">
          <Col lg={4} md={5} sm={11} className="py-4 d-flex align-items-center">
            <div className="text-center mx-auto">
              <img
                src={InstantHire}
                className="card-img-top img-fluid w-100"
                alt="Get Hired Instantly"
              />
            </div>
          </Col>
          <Col lg={4} md={5} sm={11} className="py-4">
            <div className="helveticaHead fw-semibold">Get Hired Instantly</div>
            <div class="h4 pb-2 mb-4 border-bottom border-success border-4" />

            <span className="row">
              <div className="col-auto">
                <MdArrowOutward size={35} color="#787ff6" />
              </div>
              <div className="fs-5 col">
                READY TO TAKE THE NEXT STEP IN YOUR CAREER? SKIP THE WAIT AND
                DIVE STRAIGHT INTO YOUR FUTURE WITH OUR REVOLUTIONARY VIRTUAL
                WALK-IN INTERVIEW PROCESS!
              </div>
            </span>
          </Col>
        </Row>

        {/* instruction row */}
        <div className="container d-flex justify-content-center px-5">
          <Row className="d-flex justify-items-center mb-5 flex-lg-nowrap flex-md-nowrap overflow-auto py-3 gap-3">
            {/* somehow justify between */}
            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <div className="text-center mx-auto">
                <img
                  src={step1}
                  className="card-img-top w-75 img-fluid"
                  alt="Step 1"
                />
              </div>

              <span className="row">
                <div className="col-auto">
                  <MdArrowOutward size={35} color="#787ff6" />
                </div>
                <div className="fs-5 col">
                  1. SELECT YOUR DESIRED ROLE & TIME SLOT FROM OUR GOOGLE FORM
                </div>
              </span>
            </Col>

            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <div className="text-center mx-auto">
                <img
                  src={step2}
                  className="card-img-top w-75 img-fluid"
                  alt="Step 2"
                />
              </div>

              <span className="row">
                <div className="col-auto">
                  <MdArrowOutward size={35} color="#787ff6" />
                </div>
                <div className="fs-5 col">
                  2. YOU’LL RECEIVE A CONFIRMATION EMAIL FROM US
                </div>
              </span>
            </Col>

            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <div className="text-center mx-auto">
                <img
                  src={step3}
                  className="card-img-top w-75 img-fluid"
                  alt="Step 3"
                />
              </div>

              <span className="row">
                <div className="col-auto">
                  <MdArrowOutward size={35} color="#787ff6" />
                </div>
                <div className="fs-5 col">
                  3. PREPARE WELL & ATTEND THE INTERVIEW
                </div>
              </span>
            </Col>
          </Row>
        </div>

        {/* on click */}
        <Row className="my-5 mx-auto d-flex justify-content-center">
          <div className="text-center mb-5 helveticaHead fw-bold">
            Choose your room
          </div>

          <Row className="d-flex justify-items-center flex-lg-nowrap flex-md-nowrap mb-5 overflow-auto py-3">
            <Col lg={4} md={5} sm={7} className="mx-auto">
              <div className="text-center mx-auto">
                <a
                  href={
                    googleForm1
                  } /* id="button" aria-describedby="tooltip" */
                >
                  <img
                    src={door1}
                    className="card-img-top w-75 img-fluid cardTransOut"
                    alt="door 1"
                  />
                </a>
                {/* <div id="tooltip" role="tooltip">
                  My tooltip
                </div> */}
              </div>
            </Col>

            <Col lg={4} md={5} sm={7} className="mx-auto">
              <div className="text-center mx-auto">
                <a href={googleForm2}>
                  <img
                    src={door2}
                    className="card-img-top w-75 img-fluid cardTransOut"
                    alt="door 2"
                  />
                </a>
              </div>
            </Col>

            <Col lg={4} md={5} sm={7} className="mx-auto">
              <div className="text-center mx-auto">
                <a href={googleForm3}>
                  <img
                    src={door3}
                    className="card-img-top w-75 img-fluid cardTransOut"
                    alt="door 3"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Row>

        {/* end of update */}

        <Row className="d-flex my-5 mx-auto flex-nowrap overflow-auto">
          <Col lg={4} md={5} sm={11} className="py-4">
            <div className="text-center mx-auto">
              <img
                src={Benefit1}
                className="card-img-top w-75 img-fluid"
                alt="Benefit of Using ZOM-IN"
              />
            </div>
            <div>
              <p className="text-center fs-5 fw-bold p-0 m-0">
                Fast and Easy Application
              </p>
              <p className="text-secondary text-center p-0 m-0">
                Easy application with a single click.
              </p>
            </div>
          </Col>
          <Col lg={4} md={5} sm={11} className="py-4">
            <div className="text-center mx-auto">
              <img
                src={Benefit2}
                className="card-img-top w-75 img-fluid"
                alt="Benefit of Using ZOM-IN"
              />
            </div>
            <div>
              <p className="text-center fs-5 fw-bold p-0 m-0">
                Trusted Companies
              </p>
              <p className="text-secondary text-center p-0 m-0">
                More than 70 reliable companies are hiring with us.
              </p>
            </div>
          </Col>
          <Col lg={4} md={5} sm={11} className="py-4">
            <div className="text-center mx-auto">
              <img
                src={Benefit3}
                className="card-img-top w-75 img-fluid"
                alt="Benefit of Using ZOM-IN"
              />
            </div>
            <div>
              <p className="text-center fs-5 fw-bold p-0 m-0">
                Virtual Interview
              </p>
              <p className="text-secondary text-center p-0 m-0">
                Attend your interview online to conform SOP.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="my-5 mx-auto justify-content-center">
          <p className="text-center fs-4 fw-bold mt-5">Testimonials</p>
          <Row className="d-flex flex-nowrap mb-5 overflow-auto py-3">
            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <Card className=" h-100 cardBorder">
                <Card.Body>
                  <Card.Text className="m-2">
                    "Zom-In has definitely been helpful in my search for an
                    internship placement. They're very prompt and has constantly
                    been updating and checking up on me. Definitely recommending
                    them to my colleagues."
                    <blockquote className="px-3 text-center fst-italic">
                      <span className="fw-bold">Muhammad Fakhrul Aqil</span> -
                      Intern
                    </blockquote>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <Card className=" h-100 cardBorder">
                <Card.Body>
                  <Card.Text className="m-2">
                    "Life is all about exploring and learning. ZOM-IN provides
                    an opportunity for us to explore new ideas and build up
                    connection. Opportunity is already given, keep it and move
                    towards your goals."
                    <blockquote className="px-3 text-center fst-italic">
                      <span className="fw-bold">Alex Chai</span> - College
                      Student
                    </blockquote>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={5} sm={11} className="px-lg-4 px-md-2">
              <Card className=" h-100 cardBorder">
                <Card.Body>
                  <Card.Text className="m-2">
                    "ZOM-IN creates a positive impact on the students in terms
                    of having a sense of entrepreneurship. Besides, it also
                    encourages the students to take their first step by
                    connecting them to the right peple. There is always a
                    barrier between students and the working professionals, and
                    now ZOM-IN is breaking it."
                    <blockquote className="px-3 text-center fst-italic">
                      <span className="fw-bold">Jia Wey Tan</span> - Researcher
                    </blockquote>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Row>

        <Row className="my-5 mx-auto justify-content-center">
          <p className="text-center fs-4 fw-bold mt-5">Latest Jobs</p>

          {opportunityList.slice(0, 4).map((opportunityDetails, key) => {
            const oppoID = opportunityDetails.oppo_id;
            const job_type = opportunityDetails.job_type;
            const job_title = opportunityDetails.job_title;
            const company_name = opportunityDetails.companyname;
            const job_location = opportunityDetails.job_location;
            const salary_status = opportunityDetails.salary_status;
            const salary = opportunityDetails.salary;
            const partnerLink =
              "/partner/" +
              opportunityDetails.companyname
                .toLowerCase()
                .trim()
                .split(/\s+/)
                .join("-");
            const partnerLogo =
              `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
              opportunityDetails.company_logo;

            return (
              <OpportunityShortCard
                key={oppoID}
                oppoID={oppoID}
                cardImage={partnerLogo}
                jobType={job_type}
                jobTitle={job_title}
                jobLocation={job_location}
                compName={company_name}
                compLink={partnerLink}
                salary={salary}
                salaryStatus={salary_status}
              />
            );
          })}
        </Row>

        <Row className="my-5 mx-auto justify-content-center">
          <p className="text-center fs-4 fw-bold mt-5">Our Partners</p>
          <div className="d-flex flex-nowrap overflow-auto justify-content-lg-center justify-content-md-start mb-5 mt-3 py-3">
            {partnerList.slice(0, 6).map((partnerDetails, key) => {
              const companyName = partnerDetails.company_name;
              const partnerIndustry = partnerDetails.industry;
              const partnerLink =
                "/partner/" +
                companyName.toLowerCase().trim().split(/\s+/).join("-");
              const partnerLogo =
                `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
                partnerDetails.company_logo;

              return (
                <div className="m-3 text-center" key={key}>
                  <div className="h-75 m-auto">
                    <img
                      src={partnerLogo}
                      alt={companyName}
                      href={partnerLink}
                      className=" mb-2 img-fluid rounded-circle align-middle border"
                      width="100"
                      height="100"
                    />
                  </div>
                  <p className="fs-5 d-block fw-bold p-0 m-0 text-truncate">
                    {companyName}
                  </p>
                  <p className="text-secondary d-block fs-6">
                    {partnerIndustry}
                  </p>
                </div>
              );
            })}
          </div>
        </Row>

        <Row className="my-5 mx-auto justify-content-center">
          <p className="text-center fs-4 fw-bold mt-5">Need Our Assistance?</p>
          <Col className="text-center">
            <Button href="/contact" variant="primary" size="lg">
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Main;
