import React from 'react'
import FooterImg from '../../Assests/Images/logo/logo.png'

const Footer = () => {
  return (
      <footer className="text-center w-100">
          <img style={{width:200}} src={FooterImg} alt="footer_image"/>
      </footer>
    
  )
}

export default Footer