import { useState } from "react";

const useMatch = () => {
  const [validMatch, setValidMatch] = useState(false);
  const [pwdShown, setPwdShown] = useState(false);

  const onValidMatch = (matchValue) => {
    setValidMatch(matchValue);
  };

  const togglePwd = () => {
    setPwdShown(true);
  };

  const toggleHidePwd = () => {
    setPwdShown(false);
  };

  return {
    //values
    isMatched: validMatch,
    isPwdToggle: pwdShown,
    //handlers
    onValidMatch,
    togglePwd,
    toggleHidePwd,
  };
};

export default useMatch;
