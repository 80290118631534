import React from "react";
import updateResume from "../Assests/Images/banner/SideBar/updateResume2.jpeg";
import { Link } from "react-router-dom";

function Ads() {
  return (
    <Link to="/profile">
      <img src={updateResume} alt="" className="w-100 m2" />
    </Link>
  );
}

export default Ads;
