import { useState } from "react";

const useInput = () => {
  //true when empty
  const isNotEmpty = (value) => value.trim() !== "";
  // true when under 18
  const isNotofAge = (value) =>
    new Date().getFullYear() - new Date(value).getFullYear() <= 18;
  //true when empty
  const isNotPressed = (value) => value === "";

  const [enteredValue, setEnteredValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = isNotEmpty(enteredValue);
  const hasError = !valueIsValid && isTouched;

  //additional validations for age
  const valueIsValidAge = isNotofAge(enteredValue);
  //must return false
  const notofAge = valueIsValidAge;
  //for the age to be valid it must not be under 18 that returns true
  //console.log(!valueIsValidAge);

  const notPressed = isNotPressed(enteredValue);
  const hasNotPressed = notPressed;

  const valueChangeHandler = (event) => {
    setEnteredValue(event.target.value);
  };

  //ckeditorhandler
  const editorChangeHandler = (event, editor) => {
    const data = editor.getData();
    setEnteredValue(data);
    console.log(data);
  };

  //additional validations for year
  const [enteredDate, setEnteredDate] = useState("");

  const dateChangeHandler = (date) => {
    setEnteredDate(date);
  };

  const notDated = isNotPressed(enteredDate);
  const hasNotdated = notDated;

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue("");
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
    //additional validations
    dateValue: enteredDate,
    isValidAge: !valueIsValidAge,
    isPressed: !notPressed,
    isDated: !notDated,
    notofAge,
    hasNotPressed,
    hasNotdated,
    dateChangeHandler,
    editorChangeHandler,
  };
};

export default useInput;
