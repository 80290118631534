import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//import Button from "@mui/material/Button";

//create context variable
export const BackdropContext = React.createContext();

export default function SimpleBackdrop({ children }) {
  const [open, setOpen] = React.useState(false);
  const closeBackDrop = () => {
    setOpen(false);
  };
  const showBackDrop = () => {
    setOpen(!open);
  };

  return (
    <BackdropContext.Provider value={{ closeBackDrop, showBackDrop }}>
      {/*  <Button onClick={handleToggle}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={closeBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
}
