import {useState , useEffect} from 'react'
import {BsFillCollectionFill} from 'react-icons/bs'
import Axios from 'axios'

const Skills = (props) => {
  const [skills,setSkills] = useState();
  
  useEffect(()=>{
      Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/skills/`+props.id)
      .then(res=>{
          setSkills(res.data)
      })
  },[])

  return (
    <>
        {skills && <div className="pb-3 ">
        <div className='d-flex mb-4 mt-2 '>
            <span className="border rounded bg-white text-dark px-1 pb-1">
            <BsFillCollectionFill />
            </span>
            
        <h5 className="px-2">SKILLS</h5>
        </div>
        {skills.map((skill)=>{
          const eachSkill = skill.skill
          return(
            <p key={skill.id}><span className="bg-secondary rounded px-2 py-1">{eachSkill}</span></p>
          )
        }
        )}
        

    </div>}
    </>

  )
}

export default Skills