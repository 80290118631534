import {useState , useEffect} from 'react'
import {BsAwardFill} from 'react-icons/bs'
import Axios from 'axios'

const Accomplishment = (props) => {
  const [accomplishments, setAccomplishments] = useState();
  useEffect(()=>{
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/accomplishments/`+props.id)
    .then(res=>{
      setAccomplishments(res.data)
  })
  },[])

  return (
    <>
    {accomplishments && <div className="pb-3">
        <div className='d-flex mb-4 mt-2'>
            <span className="border rounded bg-white text-dark px-1 pb-1">
            <BsAwardFill />
            </span>
            
        <h5 className="px-2">ACCOMPLISHMENT</h5>
        </div>
        <div className="text-white">
            {/* <p>Certified Coding Associate (CCA)</p>
            <p>Certified Coding Specialist (CCS)</p>
            <p>Certified Coding Specialist - Physician-based (CCS-P)</p>
            <p>Microsoft Certified Solutions Developer</p> */}
            {accomplishments.map((accomplishment)=>
              {
                const eachAccomplishment = accomplishment.accomplishment
                return (
                  <p>{eachAccomplishment}</p>      
                )
              }   
            )}
        </div>
    </div>}
    </>
  )
}

export default Accomplishment