import React, { useEffect , useState } from "react";
import Axios from 'axios';

import { Row, Col , Alert } from "react-bootstrap";
import {
  BiTime,
  BiXCircle,
  BiCheckCircle,
  BiMessageCheck,
  BiMinusCircle,
  BiErrorCircle,
} from "react-icons/bi";


import { BiChevronsRight } from "react-icons/bi";
import briefcaseIcon from "../../Assests/Images/icon/briefcase.svg";
import Ads from '../Ads'
import Profile from "../Profile";
import Name from "../Name";
import Reschedule from "../Interview/User/Reschedule";


import Heading from "../Heading";

function User() {

    const [applicationList,setApplicationList] = useState();
    const [pendingNum, setPendingNum] = useState(0);
    const [shortlistedNum, setShortlistedNum] = useState(0);
    const [hiredNum, setHiredNum] = useState(0);
    const [rejectNum, setRejectNum] = useState(0);
    const [interviewList, setInterviewList] = useState();

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/dashboard/applicationNum`, {
        headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
        ).then(
          (response) => {
            setPendingNum(response.data[0][0].pendingNum);
            setRejectNum(response.data[1][0].rejectNum);
            setHiredNum(response.data[2][0].hiredNum);
            setShortlistedNum(response.data[3][0].shortlistedNum);
          }
        );
      }, []);

      useEffect(() => {
        Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/userApplication/list`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }).then((response) => {
          setApplicationList(response.data);
        });
      }, []);

      useEffect(() => {
        Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/userInterview/list`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }).then((response) => {
          setInterviewList(response.data);
        });
      }, []);
    
  return (
    <Row>
    <Col lg={10} md={12} sm={12} className="mx-auto my-3 p-5">

    <Col lg={12} md={12} sm={12} className="mx-auto my-3">
      <Row className="m-1 text-center text-light">
        <Col className="border border-0 rounded bg-warning mx-1 p-2">
          <h3>{pendingNum}</h3>
          <h6>
            <span className="d-none d-sm-block">Application</span> Pending
          </h6>
        </Col>
        <Col className="border border-0 rounded bg-primary mx-1 p-2">
          <h3>{shortlistedNum}</h3>
          <h6>
            <span className="d-none d-sm-block">Application</span> Shortlisted
          </h6>
        </Col>
        <Col className="border border-0 rounded bg-danger mx-1 p-2">
          <h3>{rejectNum}</h3>
          <h6>
            <span className="d-none d-sm-block">Application</span> Rejected
          </h6>
        </Col>
        <Col className="border border-0 rounded bg-success mx-1 p-2">
          <h3>{hiredNum}</h3>
          <h6>
            <span className="d-none d-sm-block">Application</span> Hired
          </h6>
        </Col>
      </Row>
    </Col>

    <Col lg={12} md={12} sm={12} className="mx-auto my-3 border rounded p-2">
      <Heading content="Latest Application" design="h5 pb-1 border-bottom" />

      <Row className="px-3">
        {applicationList && applicationList.slice(0,3).map((applicationDetails)=>{
          const jobTitle = applicationDetails.job_title;
          const compName = applicationDetails.user_id;
          const jobType = applicationDetails.job_type;
          const applicationStatus =
            applicationDetails.approve_candidate;
          const grabID = applicationDetails.grab_oppo_id;
          const withdrawReason = applicationDetails.withdrawReason;

          let jobTypeBG;
          switch (jobType) {
            case "Contract":
              jobTypeBG = "bg-contract";
              break;
            case "Internship":
              jobTypeBG = "bg-internship";
              break;
            case "Part Time":
              jobTypeBG = "bg-partTime";
              break;
            default:
              jobTypeBG = "bg-fullTime";
          }

          let status;
          let statusBG;
          let statusIcon;
          switch (applicationStatus) {
            case 4:
              status = "Withdrew";
              statusBG = "link-danger";
              statusIcon = <BiMinusCircle className="icon" />;
              break;
            case 3:
              status = "Shortlisted";
              statusBG = "link-warning";
              statusIcon = <BiTime className="icon" />;
              break;
            case 2:
              status = "Accepted";
              statusBG = "link-success";
              statusIcon = <BiCheckCircle className="icon" />;
              break;
            case 1:
              status = "Rejected";
              statusBG = "link-danger";
              statusIcon = <BiXCircle className="icon" />;
              break;
            default:
              status = "Pending";
              statusBG = "link-warning";
              statusIcon = <BiTime className="icon" />;
          }
          return(
            <Row className="mx-auto my-3 p-3 border-bottom">
                          <Col sm={2} className="d-none d-md-none d-lg-block">
                            <Profile
                              id={compName}
                              design="rounded-circle"
                              width="100"
                              height="100"
                            />
                          </Col>
                          <Col xs={12} sm={8}>
                            <p className="m-0">{jobTitle}</p>
                            <p className="m-0">
                              <Name id={compName} />
                            </p>
                            <p>
                              <span
                                className={`badge rounded-pill text-dark ${jobTypeBG}`}
                              >
                                <img
                                  alt="briefcaseIcon"
                                  className="icon"
                                  src={briefcaseIcon}
                                />
                                {jobType}
                              </span>
                            </p>
                            {status === "Withdrew" && (
                              <Alert variant="danger">
                                <BiErrorCircle /> Withdrew Reason:{" "}
                                {withdrawReason}
                              </Alert>
                            )}
                          </Col>
                          <Col sm={2} className="text-center">
                            <p className={statusBG}>
                              {" "}
                              {statusIcon} {status}
                            </p>
                            {status === "Shortlisted" ||
                            status === "Pending" ? (
                              <></>
                              // <Button
                              //   variant="danger"
                              //   onClick={() => setWithdrawModal(true)}
                              //   data-bs-toggle="modal"
                              //   data-bs-target={`#col-${grabID}`}
                              // >
                              //   Withdraw
                              // </Button>
                            ) : null}
                          </Col>
                          </Row>
          )
        })}
        </Row>
        <Row>
        <Col sm={12} className="text-end fs-6">
          <p className="m-0" role="button">
            View More <BiChevronsRight />
          </p>
        </Col>
      </Row>
      </Col>
        {/* <Col lg={9} md={9} sm={12} className="fs-6 mb-2">
          <p className="mb-0 text-truncate fw-bold">
            Internship for Web Development
          </p>
          <p className="mb-0 ms-2 text-truncate">
            <a href="/mm" target="_blank" className="text-decoration-none">
              Samiksha Moghan
            </a>{" "}
          </p>
          <p className="mb-0 ms-2 text-truncate">
          samikshamogahn@gmail.com </p>
          <p className="mb-0 ms-2 text-truncate">
            <span className={`badge rounded-pill text-dark bg-fullTime`}>
              <img alt="briefcaseIcon" className="icon" src={briefcaseIcon} />
              Full Time
            </span>
          </p>
        </Col>
        <Col lg={3} md={3} sm={12}>
          <button
            type="button"
            className="col-12 btn btn-sm btn-primary mb-2"
          >
            Schedule
          </button>
          <select
            className="form-select form-select-sm text-center"
          >
            <option defaultValue="Pending">
              Pending
            </option>
            <option value="Shortlisted">Shortlisted</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>
        </Col>
     
      <hr className="m-1" />
      <Row>
        <Col sm={12} className="text-end fs-6">
          <p className="m-0" role="button">
            View More <BiChevronsRight />
          </p>
        </Col>
      </Row>*/}

    <Col lg={12} md={12} sm={12} className="mx-auto my-3 border rounded p-2">
      <Heading content="Upcoming Interview" design="h5 pb-1 border-bottom" />
      <Row>
      {interviewList&&interviewList.slice(0,2).map((interviewDetails, key) => {
              const interviewID = interviewDetails.id;
              const employerID = interviewDetails.bp_id;
              const grabID = interviewDetails.grab_id;
              const jobTitle = interviewDetails.job_title;
              const interviewerName = interviewDetails.interviewerName;
              const interviewRemarks = interviewDetails.interviewRemarks;
              const interviewLink = interviewDetails.interviewVenueOrVideo;
              const interviewingTime = new Date(interviewDetails.interviewTime);
              const interviewTimeConvert = interviewingTime
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(/ /g, " ");

              const interviewInitial = interviewingTime
                .toISOString()
                .replace(".000Z", "");

              return (
                <Row className="mx-auto my-3 p-3 border-bottom">
                  <Col sm={2} className="d-none d-md-none d-lg-block">
                    <Profile
                      id={employerID}
                      design="rounded-circle"
                      width="100"
                      height="100"
                    />
                  </Col>
                  <Col xs={12} sm={8}>
                    <div>
                      <p className="m-0">{jobTitle}</p>
                      <p className="m-0">
                        <Name id={employerID} />
                      </p>
                      <p className="m-0">Interviewer Name: {interviewerName}</p>
                      <p className="m-0">
                        <BiTime className="icon" />
                        {interviewTimeConvert}
                      </p>
                      <a
                        href={interviewLink}
                        target="_blank"
                        rel="noreferrer"
                        className="m-0 text-decoration-none"
                      >
                        {interviewLink}
                      </a>
                      <p>Remarks: {interviewRemarks}</p>
                    </div>
                    <Reschedule interviewID={interviewID} interviewerName={interviewerName} interviewLink={interviewLink}/>
                  </Col>
                  
                </Row>
              );
            })}
      </Row>
      <hr className="m-1" />
      <Row>
        <Col sm={12} className="text-end fs-6">
          <p className="m-0" role="button">
            View More <BiChevronsRight />
          </p>
        </Col>
      </Row>
    </Col>
  </Col>
  <Col lg={2} md={12} sm={12} className="p-4">
      <Ads />
  </Col>
  </Row>
  )
}

export default User