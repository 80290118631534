import { useState , useEffect} from 'react';
import Axios from 'axios'
import {Image} from 'react-bootstrap'

const ProfilePic = (props) => {
    const [profilePic, setProfilePic] = useState();

  useEffect(()=>{
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/resume/profilePic/`+props.id)
    .then(res=>{
      setProfilePic(res.data[0]);
  })
  },[])
  
  return (
    <>
          {profilePic && <Image className="w-100 rounded" src={`${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/`+profilePic.profile_photo} alt="profilePic" id="profile_pic"/>}
    </>
  )
}

export default ProfilePic