import React from 'react';
import LogoEmp from '../Assests/Images/logo/logoEmp.png';

function LogoEmployer() {
    return (
        <div>
            <img src={LogoEmp} alt="Zom-IN logo" id="logo" />
            
        </div>
    )
}

export default LogoEmployer;
