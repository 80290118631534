import { useState, useEffect, Fragment } from "react";
import { Form, Button, Modal, FormControl } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Axios from "axios";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";
import { toast } from "react-toastify";

import Heading from "../Heading";
import useInput from "../../Helpers/use-input-edit";

const EditJobModal = ({
  details,
  stateList,
  eduList,
  editFormShow,
  setEditFormShow,
}) => {
  console.log(details);
  const oppoID = details.oppo_id;
  const jobType = details.job_type;
  const jobTitle = details.job_title;
  const salary = details.salary;
  const salaryType = details.salary_type;
  const salary_Status = details.salary_status;
  const location = details.job_location;
  let yearOfExp = null;
  if (details.year_exp !== null) {
    yearOfExp = details.year_exp;
  }
  const fresh_Grade = details.fresh_graduate;
  let duration = null;
  if (details.duration !== null) {
    duration = details.duration;
  }
  let start_date = new Date(details.startdate);
  const offset = start_date.getTimezoneOffset();
  //to calculate malaysia time
  start_date = new Date(start_date.getTime() - offset * 60 * 1000);
  // console.log(start_date.toISOString().split("T")[0]);
  const education = details.education;
  const fieldOfStudy = details.field_study;
  const nationality = details.nationality;
  const interviewOption = details.iv_option;
  const test = details.test;
  const [jobDesc, setJobDesc] = useState(details.job_desc);

  const [salaryStatus, setSalaryStatus] = useState(false);
  const [freshGraduate, setFreshGraduate] = useState(false);
  const [testConduct, setTestConduct] = useState(false);

  useEffect(() => {
    if (salary_Status === 1) {
      setSalaryStatus(true);
    }
    if (fresh_Grade === 1) {
      setFreshGraduate(true);
    }
    if (test === 1) {
      setTestConduct(true);
    }
  }, []);

  const {
    value: enteredJobTitle,
    isValid: enteredJobTitleIsValid,
    hasError: jobTitleInputHasError,
    valueChangeHandler: jobTitleChangeHandler,
    inputBlurHandler: jobTitleBlurHandler,
    reset: resetjobTitle,
  } = useInput(jobTitle);

  //Salary Input Check
  const {
    value: enteredSalary,
    isValid: enteredSalaryIsValid,
    hasError: salaryInputHasError,
    valueChangeHandler: salaryChangeHandler,
    inputBlurHandler: salaryBlurHandler,
    reset: resetSalary,
  } = useInput(salary);

  //Salary Type Input Check
  const {
    value: enteredSalaryType,
    isValid: enteredSalaryTypeIsValid,
    hasError: salaryTypeInputHasError,
    valueChangeHandler: salaryTypeChangeHandler,
    inputBlurHandler: salaryTypeBlurHandler,
    reset: resetSalaryType,
  } = useInput(salaryType);

  //Location Input Check
  const {
    value: enteredLocation,
    isValid: enteredLocationIsValid,
    hasError: locationInputHasError,
    valueChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    reset: resetLocation,
  } = useInput(location);

  //Experience Year Input Check
  const {
    value: enteredExpYear,
    isValid: enteredExpYearIsValid,
    hasError: expYearInputHasError,
    valueChangeHandler: expYearChangeHandler,
    inputBlurHandler: expYearBlurHandler,
    reset: resetExpYear,
  } = useInput(yearOfExp);

  //Duration Input Check
  const {
    value: enteredDuration,
    isValid: enteredDurationIsValid,
    hasError: durationInputHasError,
    valueChangeHandler: durationChangeHandler,
    inputBlurHandler: durationBlurHandler,
    reset: resetDuration,
  } = useInput(duration);

  //StartDate Input Check
  const {
    value: enteredStartDate,
    isValid: enteredStartDateIsValid,
    hasError: startDateInputHasError,
    valueChangeHandler: startDateChangeHandler,
    inputBlurHandler: startDateBlurHandler,
    reset: resetStartDate,
  } = useInput(start_date.toISOString().split("T")[0]);

  //Edu Level Input Check
  const {
    value: enteredHighLevel,
    isValid: enteredHighLevelIsValid,
    hasError: highLevelInputHasError,
    valueChangeHandler: highLevelChangeHandler,
    inputBlurHandler: highLevelBlurHandler,
    reset: resetHighLevel,
  } = useInput(education);

  //Study Field Input Check
  const {
    value: enteredStudyField,
    isValid: enteredStudyFieldIsValid,
    hasError: studyFieldInputHasError,
    valueChangeHandler: studyFieldChangeHandler,
    inputBlurHandler: studyFieldBlurHandler,
    reset: resetStudyField,
  } = useInput(fieldOfStudy);

  //Nationality Input Check
  const {
    value: enteredNationality,
    isValid: enteredNationalityIsValid,
    hasError: nationalityInputHasError,
    valueChangeHandler: nationalityChangeHandler,
    inputBlurHandler: nationalityBlurHandler,
    reset: resetNationality,
  } = useInput(nationality);

  //Interview Option Input Check
  const {
    value: enteredInterviewOption,
    isValid: enteredInterviewOptionIsValid,
    hasError: interviewOptionHasError,
    valueChangeHandler: interviewOptionChangeHandler,
    inputBlurHandler: interviewOptionBlurHandler,
    reset: resetInterviewOption,
  } = useInput(interviewOption);

  const jobTitleClasses = jobTitleInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const salaryClasses = salaryInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const salaryTypeClasses = salaryTypeInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const locationClasses = locationInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const expYearClasses = expYearInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const durationClasses = durationInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const startDateClasses = startDateInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const highLevelClasses = highLevelInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const studyFieldClasses = studyFieldInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const nationalityClasses = nationalityInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const interviewOptionClasses = interviewOptionHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const refreshPage = () => {
    window.location.reload(false);
  };
  const editOppoHandler = () => {
    Axios.patch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/opportunity/editOpportunity`,
      {
        oppoID: oppoID,
        jobType: jobType,
        jobTitle: enteredJobTitle,
        salary: enteredSalary,
        salaryType: enteredSalaryType,
        salaryStatus: salaryStatus,
        location: enteredLocation,
        duration: enteredDuration,
        startDate: enteredStartDate,
        expYear: enteredExpYear,
        freshGraduate: freshGraduate,
        education: enteredHighLevel,
        studyField: enteredStudyField,
        nationality: enteredNationality,
        interviewOption: enteredInterviewOption,
        test: testConduct,
        jobDesc: jobDesc,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        return toast.success(
          <Fragment>
            <BiMessageCheck />
            <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });
    // console.log({
    //   enteredJobTitle,
    //   enteredSalary,
    //   salaryStatus,
    //   enteredLocation,
    //   enteredDuration,
    //   enteredExpYear,
    //   freshGraduate,
    //   enteredHighLevel,
    //   enteredStudyField,
    //   enteredNationality,
    //   enteredInterviewOption,
    //   testConduct,
    //   jobDesc,
    // });
    setEditFormShow(false);
    setTimeout(function () {
      refreshPage();
    }, 3000);
  };
  return (
    <Modal
      show={editFormShow}
      onHide={() => setEditFormShow(false)}
      scrollable
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading content="Edit Job Posting" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Job Title */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Job Title : </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Job Title"
              autoComplete="off"
              onChange={jobTitleChangeHandler}
              onBlur={jobTitleBlurHandler}
              value={enteredJobTitle}
            />
            {jobTitleInputHasError && (
              <Form.Control.Feedback type="invalid">
                Please fill in the job title.
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Salary / Allowance  */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              {jobType !== "Internship" ? <>Salary</> : <>Allowance</>}
            </Form.Label>
            {jobType === "Full Time" || jobType === "Contract" ? (
              <>
                <form>
                  <select
                    aria-label="Salary"
                    className={salaryClasses}
                    onChange={salaryChangeHandler}
                    onBlur={salaryBlurHandler}
                    value={enteredSalary}
                  >
                    <option value="">Please Choose One</option>
                    <option value="RM 1000 and Below">RM 1000 and Below</option>
                    <option value="RM 1001 to RM 2000">
                      RM 1001 to RM 2000
                    </option>
                    <option value="RM 2001 and RM 3000">
                      RM 2001 and RM 3000
                    </option>
                    <option value="RM 3001 and RM 4000">
                      RM 3001 and RM 4000
                    </option>
                    <option value="RM 4001 and RM 5000">
                      RM 4001 and RM 5000
                    </option>
                    <option value="RM 5001 and Above">RM 5001 and Above</option>
                  </select>
                </form>
                <p>
                  <input
                    type="checkbox"
                    name="salary_status"
                    checked={salaryStatus}
                    onChange={() => setSalaryStatus(!salaryStatus)}
                  />
                  Do not disclose salary range to job seeker.
                </p>
              </>
            ) : jobType === "Internship" ? (
              <>
                <select
                  aria-label="Allowance"
                  className={salaryClasses}
                  onChange={salaryChangeHandler}
                  onBlur={salaryBlurHandler}
                  value={enteredSalary}
                >
                  <option value="">Please Choose One</option>
                  <option value="RM 1000 and Below">RM 1000 and Below</option>
                  <option value="RM 1001 to RM 2000">RM 1001 to RM 2000</option>
                </select>
                <p>
                  <input
                    type="checkbox"
                    name="salary_status"
                    checked={salaryStatus}
                    onChange={() => setSalaryStatus(!salaryStatus)}
                  />
                  Do not disclose allowance range to job seeker.
                </p>
              </>
            ) : (
              <div className="row">
                <div className="col-6 pe-0">
                  <input
                    type="text"
                    placeholder="Enter salary here"
                    autoComplete="off"
                    aria-label="Salary"
                    className={salaryClasses}
                    value={enteredSalary}
                    onChange={salaryChangeHandler}
                    onBlur={salaryBlurHandler}
                  />
                </div>
                <div className="col-6">
                  <select
                    aria-label="SalaryType"
                    className={salaryTypeClasses}
                    value={enteredSalaryType}
                    onChange={salaryTypeChangeHandler}
                    onBlur={salaryTypeBlurHandler}
                  >
                    <option value="">Please Choose One</option>
                    <option value="Per Hour">Per Hour</option>
                    <option value="Per Day">Per Day</option>
                    <option value="Per Month">Per Month</option>
                  </select>
                </div>
              </div>
            )}
          </Form.Group>

          {/* Location */}
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <select
              aria-label="Location"
              className={locationClasses}
              onChange={locationChangeHandler}
              onBlur={locationBlurHandler}
              value={enteredLocation}
            >
              <option value="">Please Choose One</option>
              {stateList.map((location) => {
                return (
                  <option key={location.child_id} value={location.child_name}>
                    {location.child_name}
                  </option>
                );
              })}
            </select>
          </Form.Group>

          {/* Experience  */}
          {(jobType === "Full Time" || jobType === "Contract") && (
            <Form.Group className="mb-3">
              <Form.Label>Experience in years</Form.Label>
              <Form.Control
                type="number"
                min="0"
                max="30"
                placeholder="Enter experience in years"
                //className="form-control form-control-sm"
                className={expYearClasses}
                onChange={expYearChangeHandler}
                onBlur={expYearBlurHandler}
                value={enteredExpYear}
              />
              <p>
                <input
                  type="checkbox"
                  name="freshGraduate"
                  checked={freshGraduate}
                  onChange={() => setFreshGraduate(!freshGraduate)}
                />{" "}
                Open for Fresh Graduates.
              </p>
            </Form.Group>
          )}

          {/* Education level  */}
          <Form.Group className="mb-3">
            <Form.Label>Minimum Education level</Form.Label>
            <select
              aria-label="Minimum Education Level"
              className={highLevelClasses}
              onChange={highLevelChangeHandler}
              onBlur={highLevelBlurHandler}
              value={enteredHighLevel}
            >
              <option value="">Please Choose One</option>
              {eduList.map((eduList, index) => {
                return (
                  <option key={index} value={eduList.child_name}>
                    {eduList.child_name}
                  </option>
                );
              })}
            </select>
            {/* {highLevelInputHasError && (
              <Form.Control.Feedback type="invalid">
                Please choose your minimum education Level.
              </Form.Control.Feedback>
            )} */}
          </Form.Group>

          {/* Duration  */}
          {(jobType === "Internship" ||
            jobType === "Contract" ||
            jobType === "Part Time") && (
            <Form.Group className="mb-3">
              <Form.Label>Duration in months</Form.Label>
              <FormControl
                type="number"
                min="0"
                max="100"
                placeholder="Enter duration in months"
                //className="form-control form-control-sm"
                className={durationClasses}
                onChange={durationChangeHandler}
                onBlur={durationBlurHandler}
                value={enteredDuration}
              />
            </Form.Group>
          )}

          {/* Field of Study  */}
          {jobType !== "Part Time" && (
            <Form.Group className="mb-3">
              <Form.Label>Field of Study</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Field of Study"
                className={studyFieldClasses}
                onChange={studyFieldChangeHandler}
                onBlur={studyFieldBlurHandler}
                value={enteredStudyField}
              />

              {studyFieldInputHasError && (
                <Form.Control.Feedback type="invalid">
                  Please fill in your field of study.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}

          {/* Start Date */}
          {jobType === "Part Time" && (
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <input
                type="date"
                //placeholder="Enter start date"
                className={startDateClasses}
                onChange={startDateChangeHandler}
                onBlur={startDateBlurHandler}
                value={enteredStartDate}
              />

              {startDateInputHasError && (
                <Form.Control.Feedback type="invalid">
                  Please fill in the start date.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}

          {/* Nationality */}
          {jobType !== "Part Time" && (
            <Form.Group className="mb-3">
              <Form.Label>Nationality</Form.Label>
              <select
                defaultValue={""}
                aria-label="Nationality"
                className={nationalityClasses}
                onChange={nationalityChangeHandler}
                onBlur={nationalityBlurHandler}
                value={enteredNationality}
              >
                <option value="">Please Choose One</option>
                <option value="Malaysian only">Malaysian Only</option>
                <option value="Not specific">Not specific</option>
              </select>
              {nationalityInputHasError && (
                <Form.Control.Feedback type="invalid">
                  Please Choose One.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}

          {/* Interview Option */}
          <Form.Group>
            <Form.Label>Interview Option</Form.Label>
            <select
              defaultValue={""}
              aria-label="InterviewOption"
              className={interviewOptionClasses}
              onChange={interviewOptionChangeHandler}
              onBlur={interviewOptionBlurHandler}
              value={enteredInterviewOption}
            >
              <option value="">Please Choose One</option>
              <option value="Face to Face">Face to Face</option>
              <option value="Video Call">Video Call</option>
              <option value="Face to Face / Video Call">
                Face to Face / Video Call
              </option>
            </select>
            <p>
              <input
                type="checkbox"
                name="testConduct"
                checked={testConduct}
                onChange={() => setTestConduct(!testConduct)}
              />{" "}
              Test / Assessment will be conducted.
            </p>
            {interviewOptionHasError && (
              <Form.Control.Feedback type="invalid">
                Please Choose One
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <label htmlFor="experience" className="required">
              Job Requirement / Responsibility
            </label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                placeholder: "Job Requirement / Responsibility",
                removePlugins: [
                  "Heading",
                  // // "Essentials",
                  // // "Italic",
                  // "Bold",
                  // "Link",
                  "imageUpload",
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "BlockQuote",
                  // // "List",
                  "Indent",
                  "Table",
                ],
              }}
              data={jobDesc}
              onChange={(event, editor) => {
                const data = editor.getData();
                setJobDesc(data);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEditFormShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={editOppoHandler}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditJobModal;
