import React from 'react'
import FooterImg from '../../Assests/Images/logo/resume-header.jpeg'

const Header = () => {
  return (
    <header className="text-center w-100">
          <img src={FooterImg} alt="footer_image"/>
      </header>
  )
}

export default Header