import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { BiRightArrowCircle } from "react-icons/bi";
import CryptoJS from "crypto-js";

import { Button, Col, Row } from "react-bootstrap";
import EmailIcon from "../../Assests/Images/icon/email.svg";
import Ads from "../Ads";

import Heading from "../Heading";
import Name from "../Name";
import Email from "../Email";
import ChangeProfile from "./User/ChangeProfile";
import About from "./User/About";
import Education from "./User/Education";
import WorkExperience from "./User/WorkExperience";
import Skills from "./User/Skills";
import Achievement from "./User/Achievement";
import Portfolio from "./User/Portfolio";
import Language from "./User/Language";
import RealLifeExperience from "./User/RealLifeExperience";
import Reference from "./User/Reference";

//pfp and banner
// import EditProfile from "./User/EditProfile";
//import CoverImg from "../../Assests/Images/background/cover.png";
import ProfileBanner from "../../Components/ProfileBanner";

import { BiMinusCircle, BiMessageCheck } from "react-icons/bi";
import { toast } from "react-toastify";
import EditName from "./User/EditName";
import Deactivate from "./User/Deactivate";

function User() {
  const navigate = useNavigate();

  const { authState, setAuthState } = useContext(AuthContext);
  const userID = authState.id;

  //how to make targeted refresh
  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/signup");
    } else {
      Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/basicinfo/status/`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((response) => {
        const attemptStatus = response.data[0];
        if (attemptStatus.attempt !== 1) {
          navigate("/basicinfo");
        }
      });
    }
  });

  //get profile status
  const [profileStatus, setProfileStatus] = useState(null);
  const [profileID, setProfileID] = useState(null);
  //togggle flag
  const [employmentToggle, setEmploymentToggle] = useState(null);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboardUserProfile/profileStatus/`,
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      }
      //get from server is not an active identifier as it needs to execute the function first
      /*  const identifier = setTimeout(() => { */
      response.data.map((status) => setProfileStatus(status.looking_Job));
      response.data.map((status) => setProfileID(status.id));
      //console.log(...response.data);

      /* }, 1000);
      return () => {
        console.log("CLEANUP");
        clearTimeout(identifier);
      }; */
    });
    /* .then((data) => {
      setProfileStatus(data);
      console.log(profileStatus);
    }); */
  }, []);

  useEffect(() => {
    if (profileStatus === 1) {
      setEmploymentToggle(true);
      console.log("search");
    } else if (profileStatus === 0) {
      setEmploymentToggle(false);
      console.log("working");
    }
  }, [profileStatus]);

  //pfp state
  const [PFP, setPFP] = useState();

  //Preview PFP
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboard/userprofile/` + userID
    ).then((response) => {
      const userDetails0 = response.data[0];

      let profile;

      if (userDetails0.length > 0) {
        profile =
          `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
          userDetails0[0].profile_photo;
        setPFP(profile);
      }
    });
  }, [userID]);

  //toggleSubmit handler
  const submitToggleHandler = () => {
    let flags;
    //true means on// searching
    if (employmentToggle === true) {
      //you can put an expression to return a boolean result in a setFunction
      setEmploymentToggle(false);
      flags = 0;
      /* console.log("Employed");
      console.log(flags); */
    }
    //saved this in the state queue first
    else if (employmentToggle === false) {
      setEmploymentToggle(true);
      flags = 1;
      /* console.log("Looking for a Job");
      console.log(flags); */
    }

    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboardUserProfile/profileStatus/`,
      {
        //using the previous state not updated in state queue
        //still sending the same value because if check not triggered
        jobStatus: flags,
        profileID: profileID,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      }
      return toast.success(
        <Fragment>
          <BiMessageCheck />
          <span>{response.data[0].success}</span>
        </Fragment>
      );
    });

    //page reload
    refreshPage();
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Row className="mx-2 px-1 px-lg-5">
        <Col
          lg={8}
          md={10}
          sm={12}
          className="offset-lg-1 px-1 px-lg-5 py-3 my-1"
        >
          <Row className="mx-auto">
            <Col className="p-0">
              <ProfileBanner
                id={userID}
                alt="banner.png"
                design="img-fluid border border-1 w-100 coverImage"
              />
            </Col>
          </Row>
          <Row className="mx-auto">
            <Col className="p-0 position-relative text-end">
              {/* open profile picture change*/}
              <ChangeProfile getPFP={PFP} />
            </Col>
            <Col sm={2} md={12} lg={12} className="border p-2">
              <div className="d-flex">
                <Heading
                  content={<Name id={authState.id} />}
                  design="h5 text-truncate text-capitalize"
                />
                <EditName id={authState.id} />
              </div>

              <p className="m-0 text-start fs-7">
                {" "}
                <img src={EmailIcon} alt="" className="icon" />{" "}
                <Email id={authState.id} />
              </p>
              {/* <Row className="mx-2 text-end">
                <EditProfile getPFP2={PFP} />
              </Row> */}
            </Col>
          </Row>
          <Row className="mx-auto my-2">
            {/* fix view resume page */}
            <Col
              xs={12}
              sm={6}
              className="text-center text-md-start text-lg-start"
            >
              <a
                target="_blank"
                href={`/resume/${encodeURIComponent(
                  CryptoJS.AES.encrypt(
                    String(authState.id),
                    `${process.env.REACT_APP_ENCRYPT_CODE}`
                  )
                ).toString()}`}
                className="text-dark text-decoration-none"
              >
                <BiRightArrowCircle /> View E-Resume
              </a>
            </Col>

            {/* Toggle employment */}
            <Col xs={12} sm={6} className="text-md-end text-lg-end">
              {/* <label>Change your employment status</label> */}
              <div className="form-check form-switch d-inline-block">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  //readOnly
                  checked={employmentToggle}
                  onClick={submitToggleHandler}
                />
                {profileStatus === 0 && (
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    Currently with occupation
                  </label>
                )}
                {profileStatus === 1 && (
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    Looking for a Job
                  </label>
                )}
              </div>
            </Col>
          </Row>
          {/* fix this */}
          <About id={authState.id} />
          <Education id={authState.id} />
          <WorkExperience id={authState.id} />
          <Skills id={authState.id} />
          <Achievement id={authState.id} />
          <Portfolio id={authState.id} />
          <Language id={authState.id} />
          <RealLifeExperience id={authState.id} />
          <Reference id={authState.id} />
          <Deactivate id={authState.id} />
        </Col>
        <Col lg={2} md={2} sm={12} className="px-1 py-3 my-1 mx-auto">
          <Ads />
        </Col>
      </Row>
    </AuthContext.Provider>
  );
}

export default User;
