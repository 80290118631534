import React, { useState, useContext, Fragment, useEffect } from "react";
import Axios from "axios";

import "../Utils/cropper.css";

import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider/Slider";

import { AuthContext } from "../../../Helpers/AuthContext";
import Profile from "../../Profile";

import getCroppedImg from "../Utils/cropImage";
import { dataURLtoFile } from "../Utils/dataURLtoFile";
import { BackdropContext } from "../Utils/backdrop";

import { Row, Col, Form } from "react-bootstrap";
import { FcCamera } from "react-icons/fc";
import { BsXCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { BiMinusCircle, BiMessageCheck } from "react-icons/bi";

import Heading from "../../Heading";

function ChangeProfile(props) {
  const { authState, setAuthState } = useContext(AuthContext);
  const userID = authState.id;
  //getPFP
  const pfp = props.getPFP;
  //console.log(pfp);

  //Image State
  const [postImage, setPostImage] = useState(pfp);
  //const [postImageName, setPostImageName] = useState("");
  const [imageLength, setImageLength] = useState(false);

  //crop states
  //const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  //backrop context //use bootstrap alternative
  const { closeBackDrop, showBackDrop } = useContext(BackdropContext);

  //rerender pfp state
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    setPostImage(pfp);
  }, [pfp]);

  //crop preview
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setPostImage(reader.result);
        //console.log(reader.result);
        setImageLength(true);
      });
    }
  };

  let createPostFormIsValid = false;

  if (imageLength) {
    createPostFormIsValid = true;
  }

  //IMAGE INPUT //deprecated
  /* const imageChange = (event) => {
    let imageFile = event.target.files[0];
    console.log(imageFile);

    if (event.target.files && event.target.files.length > 0) {
      setPostImage(imageFile);
      setPostImageName(imageFile.name);
      setImageLength(true);
    }

    if (imageFile.size > 5e6) {
      setPostImage();
      setImageLength(false);
      return toast.warn(
        <Fragment>
          <span>Please upload a file smaller than 5 MB</span>
        </Fragment>
      );
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setPostImage();
      setImageLength(false);
      return toast.warn(
        <Fragment>
          <span>
            Invalid File Upload. Please Try to Upload file with jpg, jpeg, png
            or gif
          </span>
        </Fragment>
      );
    }
  }; */

  //remove preview
  const removeSelectedImage = () => {
    setPostImage("");
    setImageLength(false);
  };

  //submit handler
  const submitProfileImageHandler = async (event) => {
    event.preventDefault();
    //pfp form upload
    const canvas = await getCroppedImg(postImage, croppedArea);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedURLtoFile = dataURLtoFile(
      canvasDataUrl,
      "cropped-image.jpeg"
    );

    const formData = new FormData();
    formData.append("file", convertedURLtoFile);
    formData.append("fileName", convertedURLtoFile.name);
    formData.append("userID", userID);

    showBackDrop();

    //post pfp imagePath
    await Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/userDashboard/upload__ProfileImage`,
      formData
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        //response has been parsed
        closeBackDrop();
        //console.log(response.data.imagePath);
        setAvatar(response.data.imagePath);

        toast.success(
          <Fragment>
            <BiMessageCheck /> <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });
    document.getElementById("closeButton").click();
    //setPostImage("");
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <div className="me-5">
        <button data-bs-toggle="modal" data-bs-target="#profilePicModal">
          <Profile
            renderAvatar={avatar}
            id={authState.id}
            design="border border-3 border-white rounded-circle bg-white 
            position-absolute top-0 end-0 translate-middle-y me-4"
            width="160"
            height="160"
          />
        </button>
      </div>

      <div
        className="modal fade"
        id="profilePicModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Heading content="Change your profile picture" design="h5 " />
              <button
                type="button"
                id="closeButton"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <Form
              className="createPostForm"
              onSubmit={submitProfileImageHandler}
              encType="multipart/form-data"
            >
              <div className="modal-body mt-3 mb-0 mx-0 p-0">
                {/* Image preview Deprecated*/}
                {/* {postImage && (
                  <div className="m-2 w-75 mx-auto border border-5 themeColorUser rounded imgPostPreview">
                    <div className="position-relative">
                      <div className="position-absolute top-0 start-100 translate-middle">
                        <button
                          onClick={removeSelectedImage}
                          className="bg-transparent rounded-circle border-0 position-relative"
                        >
                          <BsXCircleFill
                            size={21}
                            className="bg-white rounded-circle"
                          />
                        </button>
                      </div>
                    </div>
                    <img
                      src={URL.createObjectURL(postImage)}
                      className="w-100"
                      alt=" Upload"
                    />
                  </div>
                )} */}
                {postImage && (
                  /* border border-5 themeColorUser rounded */
                  <div className="m-2 w-100 mx-auto imgPostPreview">
                    <div className="position-relative">
                      <div className="position-absolute top-0 start-100 translate-middle mt-2">
                        <button
                          onClick={removeSelectedImage}
                          className="bg-transparent rounded-circle border-0 position-relative"
                        >
                          <BsXCircleFill
                            size={35}
                            className="bg-white rounded-circle"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="container-cropper">
                      <div className="cropper">
                        <Cropper
                          image={postImage}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                      </div>
                    </div>
                    <div className="slider">
                      <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Row>
                  <Col xs={6} sm={6} className="text-md-start text-lg-start">
                    <span className=" badge fw-bold border-0 text-dark p-3 fs-6 position-relative overflow-hidden themeColorUser inputPostFile">
                      <FcCamera className="icon" /> Select picture
                      <input
                        type="file"
                        accept="image/*"
                        onChange={onSelectFile}
                      />
                    </span>
                  </Col>

                  <Col className="text-md-end text-lg-end">
                    <button
                      type="submit"
                      disabled={!createPostFormIsValid}
                      className="btn btn-primary"
                    >
                      Apply
                    </button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default ChangeProfile;
