import React, { Fragment, useEffect, useState } from 'react';
import { useParams , } from 'react-router-dom';
import { Col, Container , Form } from 'react-bootstrap'

import Axios from 'axios';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Heading from "../Components/Heading";

//Toast
import { toast } from "react-toastify";

import useInput from "../Helpers/use-input";

const isNotPassword = (value) =>
  value.match(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/
  );

function ResetPasswordToken() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [validToken, setValidToken] = useState(false);

    useEffect(() => {
        if (token) {
            Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/verify/resetpassword/token`, {
                token: token
            })
                .then((response) => {
                    if (response.data.success) {
                        return [toast.success(
                            <Fragment>
                                <BiMessageCheck />
                                <span>{response.data.success}</span>
                            </Fragment>
    
                        ),setValidToken(true)];
                    } else if (response.data.warn) {
                        return toast.warn(
                            <Fragment>
                                <BiError />
                                <span>{response.data.warn}</span>
                            </Fragment>);
                    }  else {
                        return toast.error(
                            <Fragment>
                                <BiMinusCircle />
                                <span>{response.data.error}</span>
                            </Fragment>);
                    }
                })
        }
        else {
            return toast.error(
                <Fragment>
                    <BiMinusCircle />
                    <span>System is Error. Please Try Again!</span>
                </Fragment>);
        }
    
    
    
        if (token === null) {
            navigate("/");
        }
      }, []);

    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    
    const toggleNewPassword = () => {
    setNewPassword(!newPassword);
    };

    const toggleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
    };

// New Password Input Check
const {
    value: enteredNewPassword,
    isValid: enteredNewPasswordIsValid,
    hasError: newPasswordInputHasError,
    valueChangeHandler: newPasswordChangeHandler,
    inputBlurHandler: newPasswordBlurHandler,
    reset: resetNewPasswordInput,
  } = useInput(isNotPassword);

  // Confirm Password Input Check
  const {
    value: enteredConfirmPassword,
    isValid: enteredConfirmPasswordIsValid,
    hasError: confirmPasswordInputHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
    reset: resetConfirmPasswordInput,
  } = useInput(isNotPassword);

  let changePasswordIsValid = false;

  if (enteredNewPassword && enteredConfirmPassword) {
    changePasswordIsValid = true;
  }

    const resetPasswordFormSubmissionHandler=(event)=>{
        event.preventDefault();
        if(enteredNewPassword!==enteredConfirmPassword){
            return toast.warn(
                <Fragment>
                    <BiError /> <span>New Password and Confirm Password does not match!</span>
                </Fragment>
            )
        }else{
            if(!enteredNewPasswordIsValid && !enteredConfirmPasswordIsValid){
                return;
            }else{
                Axios.patch(
                    `${process.env.REACT_APP_SERVER_DOMAIN}/resetPassword/reset`,
                    {
                        newPassword: enteredNewPassword,
                        confirmPassword: enteredConfirmPassword,
                        token: token
                    }
                ).then((response)=>{
                    if (response.data.error) {
                        return toast.error(
                          <Fragment>
                            <BiMinusCircle /> <span>{response.data.error}</span>
                          </Fragment>
                        );
                      } else if (response.data.warn) {
                        return toast.warn(
                          <Fragment>
                            <BiError /> <span>{response.data.warn}</span>
                          </Fragment>
                        );
                      } else {
                        navigate("/Signup")
                        return toast.success(
                            <Fragment>
                              <BiMessageCheck /> <span>{response.data.success}</span>
                            </Fragment>
                            
                        );
                          
                      }
                })
            }
            resetNewPasswordInput();
            resetConfirmPasswordInput();
        }

    }
  const newPasswordClasses = newPasswordInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const confirmPasswordClasses = confirmPasswordInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

    return (
        <Fragment>
           <p>{validToken ? 
           <div id="resetPassword" className="resetPassword vh-100">
           <Container>
               <Col lg={10} md={12} sm={12} className="mx-auto my-2">
               <Heading content="Reset Password" design=" h3 mb-4" />
               <Form onSubmit={resetPasswordFormSubmissionHandler}>
               <Col sm={12} className="form-group mb-3">
                   <label htmlFor="studyField" className="required">
                   New Password
                   </label>
                   <div className="input-group mb-3">
                   <input
                       type={newPassword ? "text" : "password"}
                       className={newPasswordClasses}
                       onChange={newPasswordChangeHandler}
                       onBlur={newPasswordBlurHandler}
                       value={enteredNewPassword}
                   />
                   <span className="input-group-text" onClick={toggleNewPassword}>
                       {newPassword === true ? <BsEyeFill /> : <BsEyeSlashFill />}
                   </span>

                   {newPasswordInputHasError && (
                       <Form.Control.Feedback type="invalid">
                       Must contain more than 8 characters which contain at
                               least one lowercase letter, one uppercase letter, one
                               numeric digit, and one special character.
                       </Form.Control.Feedback>
                   )}
                   </div>
               </Col>

               <Col sm={12} className="form-group mb-3">
                   <label htmlFor="studyField" className="required">
                   Confirm Password
                   </label>
                   <div className="input-group mb-3">
                   <input
                       type={confirmPassword ? "text" : "password"}
                       className={confirmPasswordClasses}
                       onChange={confirmPasswordChangeHandler}
                       onBlur={confirmPasswordBlurHandler}
                       value={enteredConfirmPassword}
                   />
                   <span
                       className="input-group-text"
                       onClick={toggleConfirmPassword}
                   >
                       {confirmPassword === true ? <BsEyeFill /> : <BsEyeSlashFill />}
                   </span>

                   {confirmPasswordInputHasError && (
                       <Form.Control.Feedback type="invalid">
                       Must contain more than 8 characters which contain at
                               least one lowercase letter, one uppercase letter, one
                               numeric digit, and one special character.
                       </Form.Control.Feedback>
                   )}
                   </div>
               </Col>

               <Col className="text-end">
                   <button type="submit" className="btn btn-primary" disabled={!changePasswordIsValid}>
                   Save
                   </button>
               </Col>
               </Form>
           </Col>
           </Container>
     
       </div> : "Invalid token"}</p>
        </Fragment>
    );


}

export default ResetPasswordToken;