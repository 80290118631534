import React from 'react'
// import "react-loader-spinner/dist/loader/Circles";
import { Rings } from 'react-loader-spinner'

const LoaderSpinner = () => {
  return (
    <div align='center' className="py-5 my-5">
          <Rings
          className="px-5 justify-content-center w-auto"
    height="100"
    width="auto"
    color="#0d6efd"
    ariaLabel='loading'
    timeout={3000}
  />
  </div>
  )
}

export default LoaderSpinner