import { useState, useEffect } from "react";
import Axios from "axios";
import ReactHtmlParser from "react-html-parser";

import briefcaseIcon from "../../Assests/Images/icon/briefcase.svg";

const WorkExperience = (props) => {
  const [experiences, setExperiences] = useState();
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/resume/workExperiences/` +
        props.id
    ).then((res) => {
      setExperiences(res.data);
    });
  }, []);
  return (
    <div>
      {experiences && (
        <>
          <div className="d-flex my-2">
            <span
              className="border rounded text-light px-2 pb-1 my-auto py-1 bg-dark"
              id="workIcon"
            >
              <img alt="briefcaseIcon" className="icon" src={briefcaseIcon} />
            </span>

            <h3 className="px-2" id="catTitle">
              WORK EXPERIENCE
            </h3>
          </div>

          <div className="p-3">
            {experiences.map((experience, key) => {
              const position = experience.cur_position;
              const company = experience.comp_name_user;
              const status = experience.employ_status;
              const employExp = experience.work_exp;
              let startDate;
              let endDate;
              if (experience.start_date && experience.end_date) {
                const startd = new Date(experience.start_date);
                startDate = startd.toLocaleDateString("en-GB", {
                  month: "numeric",
                  year: "numeric",
                });

                const endd = new Date(experience.end_date);
                endDate = endd.toLocaleDateString("en-GB", {
                  month: "numeric",
                  year: "numeric",
                });
              }
              return (
                <div className="py-2" key={key}>
                  <p className="my-0">
                    <h4>{position}</h4>
                  </p>
                  <p className="my-0">
                    <h6>
                      {company} | {status}
                    </h6>
                  </p>
                  <p className="text-warning mb-1">
                    {startDate} - {endDate}
                  </p>
                  {employExp !== "" && ReactHtmlParser(employExp)}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default WorkExperience;
