import React, { useState, useEffect } from "react";
import Axios from "axios";

function Name(props) {
  const userID = props.id;
  const design = props.design;
  const width = props.width;
  const height = props.height;
  //rerender pfp
  const avatar = props.renderAvatar;

  //profile picture settings
  const [viewProfile, setViewProfile] = useState();

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboard/userprofile/`+userID
    ).then((response) => {
      //console.log(...response.data[0][0].profile_photo);
      const userDetails0 = response.data[0];
      const userDetails1 = response.data[1];
      const userDetails2 = response.data[2];

      let profile;

      if (userDetails0.length === 0) {
        profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/male.svg`;
        setViewProfile(profile);
      }
      //if no image 
      /* else if (!avatar === null) {
        profile =
          `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
          avatar;
        setViewProfile(profile);
        console.log(profile);
      } */
      //current pfp
      else if (userDetails0.length > 0) {
        profile =
          `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
          userDetails0[0].profile_photo;
        setViewProfile(profile);
        //console.log(profile);
      }
      //pfp for other type of user
      else {
        if (userDetails1[0].company === 1) {
          profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/bp.png`;
          setViewProfile(profile);
        } else {
          if (userDetails2.length > 0) {
            if (userDetails2[0].gender === "Female") {
              profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/female.svg`;
              setViewProfile(profile);
            }
          }
        }
      }

      // check role comp
      //if comp = 1 then show BP img
      //else go to line 30

      //check gender
      //if female then show female.svg

      // const userDetails0 = response.data[0];
      // const userDetails1 = response.data[1];
      // const userDetails2 = resonse.data[2];

      // var profile;

      // if (userDetails1.length === 0) {
      //   if (userDetails0.company === 1) {
      //     profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/bp.png`;
      //     setViewProfile(profile);
      //   } else {
      //     if (userDetails2[0].gender === "Male") {
      //       profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/male.svg`;
      //       setViewProfile(profile);
      //     } else {
      //       profile = `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/avatar/female.svg`;
      //       setViewProfile(profile);
      //     }
      //   }
      // } else {
      //   profile =
      //     `${process.env.REACT_APP_SERVER_DOMAIN}/public/Assests/Images/user/` +
      //     userDetails1[0].profile_photo;
      //   setViewProfile(profile);
      // }
    });
  }, [userID, avatar]);

  return (
    <div>
      <img
        src={viewProfile}
        className={design}
        alt="profile_pic"
        width={width}
        height={height}
      />{" "}
    </div>
  );
}

export default Name;
