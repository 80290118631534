import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { AuthContext } from "../Helpers/AuthContext";
import { Container, Row, Col } from "react-bootstrap";
import SubMenu from "../Components/Settings/SubMenu";
import Password from "../Components/Settings/Password";
import UserName from "../Components/Settings/UserName";

import Ads from "../Components/Ads";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";

function Settings() {
  const { authState, setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/signup");
    }
  });

  const [showSubContent, setshowSubContent] = useState(1);
  const subContentStatus = (currentSubContent) => {
    setshowSubContent(currentSubContent);
  };

  let mobileStatus;
  if (window.innerWidth < 575) {
    if (showSubContent > 0) {
      mobileStatus = "d-none";
    } else {
      mobileStatus = "d-block";
    }
  }

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <HelmetProvider>
          <Helmet>
            <title> Settings | Zom - IN </title>
          </Helmet>
        </HelmetProvider>
        <div id="settings" className="settings my-2">
          <Container>
            <Row>
              <Col lg={2} md={3} sm={12} className={`mb-4 ${mobileStatus}`}>
                {/*  userName as 2*/}
                <Row
                  className="d-flex justify-content-between border-bottom"
                  onClick={(e) => subContentStatus(2)}
                  role="button"
                >
                  <SubMenu content="User Name" />
                </Row>
                {/* password as default 1*/}
                <Row
                  className="d-flex justify-content-between border-bottom"
                  onClick={(e) => subContentStatus(1)}
                  role="button"
                >
                  <SubMenu content="Password" />
                </Row>
              </Col>

              <Col lg={8} md={9} sm={12} className={!mobileStatus}>
                {/* default setting status */}
                {showSubContent === 1 && (
                  <Password
                    showSubContent={showSubContent}
                    setshowSubContentClick={setshowSubContent}
                  />
                )}
                {/* show secondary settings according to statuses */}
                {showSubContent === 2 && (
                  <UserName
                    showSubContent={showSubContent}
                    setshowSubContentClick={setshowSubContent}
                  />
                )}
              </Col>
              <Col lg={2} className="d-none d-md-none d-lg-block">
                <Ads />
              </Col>
            </Row>
          </Container>
        </div>
        <ScrollToTop />
        <Footer />
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Settings;
