import React, { useEffect, useState } from "react";
import Axios from "axios";

import ContactIcon from "../../Assests/Images/icon/contact.svg";
import LocationIcon from "../../Assests/Images/icon/location.svg";
import EmailIcon from "../../Assests/Images/icon/email.svg";
import { ImLinkedin } from "react-icons/im";

const Contact = (props) => {
  const [contact, setContact] = useState();
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/resume/contact/` + props.id
    ).then((res) => {
      setContact(res.data[0]);
    });
  }, []);
  return (
    <div className="border rounded py-5 px-3 text-white h-100 bg-dark">
      {contact && (
        <>
          {contact.contnum && (
            <p>
              <img alt="" src={ContactIcon} className="icon" />
              {contact.contnum}
            </p>
          )}

          {contact.email && (
            <p>
              <img alt="" src={LocationIcon} className="icon" />
              {contact.email}
            </p>
          )}

          {contact.link && (
            <p>
              <span className="border rounded bg-white text-dark px-1 pb-1">
                <ImLinkedin />
              </span>

              <span className="px-2">{contact.link}</span>
            </p>
          )}
          {contact.user_address && (
            <p>
              <img alt="" src={LocationIcon} className="icon" />
              {contact.user_address}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Contact;
