import { useState, Fragment } from "react";
import Axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { BiMessageCheck, BiMinusCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Deactivate = ({ id }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  console.log(id);
  const logout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
    // setAuthState({
    //   id: "",
    //   admin: "",
    //   company: "",
    //   attempt: "",
    //   status: false,
    // });
  };
  const handleDeactivate = () => {
    Axios.patch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboardUserProfile/deactivate`,
      {
        id: id,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        return toast.success(
          <Fragment>
            <BiMessageCheck />
            <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });
    handleClose();
    logout();
  };
  return (
    <div className="mt-5 d-flex justify-content-center">
      <Button variant="danger" onClick={() => setShow(true)}>
        Deactivate account
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">
            *Deactivate Account*
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="text-danger">*</span>Deactivating your account will
          prevent you from logging into Zom-IN, but your data and information
          will be retained. If you wish to delete your data permanently, please
          contact our support team. Are you sure you want to proceed with
          deactivating your account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeactivate}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Deactivate;
