import { Fragment } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import Axios from "axios";
import Heading from "../Heading";
import { toast } from "react-toastify";
import { BiError, BiMessageCheck, BiMinusCircle } from "react-icons/bi";

const DeleteOppoModal = ({ oppoID, jobTitle, show, setShow }) => {
  //refresh page
  const refreshPage = () => {
    window.location.reload(false);
  };

  //delete opportunity handler
  const deleteOppoHandler = () => {
    Axios.patch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/opportunity/deleteOpportunity`,
      {
        oppoID: oppoID,
      },
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else if (response.data.warn) {
        return toast.warn(
          <Fragment>
            <BiError />
            <span>{response.data.warn}</span>
          </Fragment>
        );
      } else {
        return toast.success(
          <Fragment>
            <BiMessageCheck />
            <span>{response.data.success}</span>
          </Fragment>
        );
      }
    });

    setShow(false);
    setTimeout(function () {
      refreshPage();
    }, 1000);
  };
  return (
    <Modal show={show} onHide={() => setShow(false)} scrollable size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading content="Delete Opportunity" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Confirm deleting job posting <br />"{jobTitle}"?
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="danger" onClick={deleteOppoHandler}>
          Confirm delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteOppoModal;
