import React, { Fragment, useEffect, useState, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//SESSION and INPUT
import { AuthContext } from "../../Helpers/AuthContext";
import useInput from "../../Helpers/use-input";
import useTypeHead from "../../Helpers/use-typeHead";

import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { BiMessageCheck, BiMinusCircle } from "react-icons/bi";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";

//Toast
import { toast } from "react-toastify";

//Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Components
import Heading from "../Heading";

//const isNotEmpty = (value) => value.trim() !== "";
//const isNotArray = (value) => value.length !== 0;

function User() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  const [universityList, setUniversityList] = useState([]);
  const [eduList, setEduList] = useState([]);
  const [studyList, setstudyList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [profileStatus, setProfileStatus] = useState([]);

  //PROFILE CHECK
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/dashboardUserProfile/profileStatus/`,
      {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    ).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <BiMinusCircle /> <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        setProfileStatus(response.data); 
      }
    });
  }, []);
  console.log(profileStatus.length);


  //University
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/university`).then(
      (response) => {
        const uni = response.data;
        var rows = [];
        for (var i = 0; i < uni.length; i++) {
          rows.push(response.data[i].university_name);
        }
        setUniversityList(rows);
      }
    );
  }, []);

  //Education Level
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/eduLevel`).then(
      (response) => {
        setEduList(response.data);
      }
    );
  }, []);

  //Study field
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/study`).then(
      (response) => {
        const study = response.data;
        const rows = [];
        for (let i = 0; i < study.length; i++) {
          rows.push(response.data[i].child_name);
        }
        setstudyList(rows);
      }
    );
  }, []);

  //skills
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/meta/skills`).then(
      (response) => {
        const skills = response.data;
        const rows = [];
        for (let i = 0; i < skills.length; i++) {
          rows.push(response.data[i].child_name);
        }
        setSkillList(rows);
      }
    );
  }, []);

  const [currentStatus, setCurrentStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
  const jobStatus = (currentStatus) => {
    setCurrentStatus(currentStatus);
  };

  //GENDER Input Check
  //const [genderInput, setGenderInput] = useState("");
  const {
    value: genderInput,
    isPressed: enteredGenderIsValid,
    hasNotPressed: genderPress,
    valueChangeHandler: genderChangeHandler,
    reset: resetGender,
  } = useInput();

  //DOB Input Check
  const {
    value: enteredDob,
    isValidAge: enteredDobIsValid,
    hasError: dobInputHasError,
    notofAge: dobAgeError,
    valueChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
    reset: resetDobInput,
  } = useInput(/* isNotEmpty */);

  //About Input Check
  const {
    value: enteredAbout,
    isValid: enteredAboutIsValid,
    hasError: aboutInputHasError,
    valueChangeHandler: aboutChangeHandler,
    inputBlurHandler: aboutBlurHandler,
    reset: resetAboutInput,
  } = useInput(/* isNotEmpty */);

  //School Input Check
  //const [school, setSchool] = useState("");
  const {
    value: enteredCollege,
    isValid: enteredCollegeIsValid,
    hasError: collegeInputHasError,
    valueChangeHandler: collegeChangeHandler,
    inputBlurHandler: collegeBlurHandler,
    reset: resetCollegeInput,
  } = useTypeHead(/* isNotArray */);

  //Highest Education Level Input Check
  const {
    value: enteredHighLevel,
    isValid: enteredHighLevelIsValid,
    hasError: highLevelInputHasError,
    valueChangeHandler: highLevelChangeHandler,
    inputBlurHandler: highLevelBlurHandler,
    reset: resetHighLevelInput,
  } = useInput();

  //Study Field Level Input Check
  const {
    value: enteredStudyField,
    isValid: enteredStudyFieldIsValid,
    hasError: studyFieldInputHasError,
    valueChangeHandler: studyFieldChangeHandler,
    inputBlurHandler: studyFieldBlurHandler,
    reset: resetStudyFieldInput,
  } = useTypeHead(/* isNotArray */);

  //StartEnd Year Input Check
  //const [startDate, setStartDate] = useState();
  const {
    dateValue: startYear,
    isDated: enteredStartYearIsValid,
    hasNotdated: startYearPress,
    dateChangeHandler: startYearChangeHandler,
    reset: resetStartYear,
  } = useInput();

  //End Year Input Check
  //const [endDate, setEndDate] = useState();
  const {
    dateValue: endYear,
    isDated: enteredEndYearIsValid,
    hasNotdated: endYearPress,
    dateChangeHandler: endYearChangeHandler,
    reset: resetEndYear,
  } = useInput();

  //Current Status Level Input Check
  const {
    value: enteredCurrentStatus,
    isValid: enteredCurrentStatusIsValid,
    //universal value for 3 input need one status
    hasNotPressed: currentStatusInputHasError,
    valueChangeHandler: currentStatusChangeHandler,
    inputBlurHandler: currentStatusBlurHandler,
    reset: resetCurrentStatusInput,
  } = useInput();

  //Company Name Input Check
  //const [companyName, setCompanyName] = useState("");
  const {
    value: companyName,
    //find a way to block after status check
    //isValid: companyNameIsValid,
    hasError: companyNameHasError,
    valueChangeHandler: setCompanyName,
    inputBlurHandler: companyNameBlurHandler,
    reset: resetCompanyName,
  } = useInput();

  //Current Position Input Check
  //const [currentPosition, setCurrentPosition] = useState("");
  const {
    value: currentPosition,
    //find a way to block after status check
    //isValid: companyNameIsValid,
    hasError: currentPositionHasError,
    valueChangeHandler: setCurrentPosition,
    inputBlurHandler: currentPositionBlurHandler,
    reset: resetCurrentPosition,
  } = useInput();

  //internship check
  //const [bgCourse, setBgCourse] = useState("");
  const {
    value: bgCourse,
    //find a way to block after status check
    //isValid: companyNameIsValid,
    hasError: bgCourseHasError,
    //valueChangeHandler: setBgCourse,
    editorChangeHandler: setEditorChangeHandler,
    inputBlurHandler: bgCourseBlurHandler,
    reset: resetBgCourse,
  } = useInput();

  //const [startIntern, setStartIntern] = useState();
  const {
    value: startIntern,
    //find a way to block after status check
    //isValid: companyNameIsValid,
    hasError: startInternHasError,
    valueChangeHandler: setStartIntern,
    inputBlurHandler: startInternBlurHandler,
    reset: resetStartIntern,
  } = useInput();
  //const [endIntern, setEndIntern] = useState();
  const {
    value: endIntern,
    //find a way to block after status check
    //isValid: companyNameIsValid,
    hasError: endInternHasError,
    valueChangeHandler: setEndIntern,
    inputBlurHandler: endInternBlurHandler,
    reset: resetEndIntern,
  } = useInput();

  //Skills input check
  //const [skill, setSkill] = useState("");
  const {
    value: enteredSkills,
    isValid: enteredSkillsIsValid,
    hasError: skillsInputHasError,
    valueChangeHandler: skillsChangeHandler,
    inputBlurHandler: skillsBlurHandler,
    reset: resetSkillsInput,
  } = useTypeHead();

  let userBasicInfoFormIsValid = false;

  if (
    //genderInput !== "" &&
    enteredGenderIsValid &&
    enteredDobIsValid &&
    enteredAboutIsValid &&
    enteredCollegeIsValid &&
    enteredHighLevelIsValid &&
    enteredCurrentStatusIsValid &&
    enteredStudyFieldIsValid &&
    enteredSkillsIsValid &&
    enteredStartYearIsValid &&
    enteredEndYearIsValid
  ) {
    userBasicInfoFormIsValid = true;
  }

  const userBasicInfoFormSubmissionHandler = (event) => {
    event.preventDefault();

    if (
      //genderInput === "" &&
      !enteredGenderIsValid &&
      !enteredDobIsValid &&
      !enteredAboutIsValid &&
      !enteredCollegeIsValid &&
      !enteredHighLevelIsValid &&
      !enteredCurrentStatusIsValid &&
      !enteredStudyFieldIsValid &&
      !enteredSkillsIsValid &&
      !enteredStartYearIsValid &&
      !enteredEndYearIsValid
    ) {
      return;
    } else {
      Axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/basicinfo/user`,
        {
          gender: genderInput,
          dob: enteredDob,
          about: enteredAbout,
          school: enteredCollege,
          eduLevel: enteredHighLevel,
          studyField: enteredStudyField,
          startDate: startYear,
          endDate: endYear,
          currentStatus: enteredCurrentStatus,
          companyName: companyName,
          currentPosition: currentPosition,
          bgCourse: bgCourse,
          startIntern: startIntern,
          endIntern: endIntern,
          skill: enteredSkills,
          //about update
          profileUpdateId: profileStatus

        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((response) => {
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiMinusCircle /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      });
    }
    //setGenderInput("");
    resetGender();
    resetDobInput();
    resetAboutInput();
    resetCollegeInput();
    resetHighLevelInput();
    resetCurrentStatusInput();
    resetStudyFieldInput();
    resetSkillsInput();
    resetStartYear();
    resetEndYear();
    //statuses reset
    resetCompanyName();
    resetCurrentPosition();
    resetBgCourse();
    resetStartIntern();
    resetEndIntern();
    //nav to homepage after basic data is saved
    navigate("/");
  };

  //somehow this has higher priority
  //[] triggers true first statement
  //remove redundant className to prevent stacking
  const genderClasses = genderPress ? "is-invalid" : "";

  const yearClasses = startYearPress ? "is-invalid" : "";
  const yearClasses2 = endYearPress ? "is-invalid" : "";

  const dobClasses = dobInputHasError ? "is-invalid" : "";
  const dobClasses2 = dobAgeError ? "is-invalid" : "";

  const statusClasses = currentStatusInputHasError ? "is-invalid" : "";

  const companyClasses = companyNameHasError ? "is-invalid" : "";
  const positionClasses = currentPositionHasError ? "is-invalid" : "";

  const bgCourseClasses = bgCourseHasError ? "is-invalid" : "";
  const startInternClasses = startInternHasError ? "is-invalid" : "";
  const endInternClasses = endInternHasError ? "is-invalid" : "";

  const aboutClasses = aboutInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const collegeClasses = collegeInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const highLevelClasses = highLevelInputHasError
    ? "form-select form-select-sm is-invalid"
    : "form-select form-select-sm";

  const studyFieldClasses = studyFieldInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const skillsClasses = skillsInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <HelmetProvider>
          <Helmet>
            <title> Basic Info | Zom - IN </title>
          </Helmet>
        </HelmetProvider>
        <div id="basicInfo" className="basicInfo">
          <Container className="my-5">
            <Row>
              <Col sm={6} xs={12} className="mx-auto">
                <Heading content="Basic Info" design="h4 mb-5" />
                <Form onSubmit={userBasicInfoFormSubmissionHandler}>
                  <label htmlFor="inputgender" className="required">
                    Gender
                  </label>
                  <Col sm={12} className="form-group mb-3 d-flex">
                    <div className="mx-auto">
                      <InputGroup
                        className={
                          `form-control form-control-sm ` + genderClasses
                        }
                      >
                        <div>
                          <input
                            type="radio"
                            className="btn-check"
                            name="gender"
                            required
                            id="male"
                            autoComplete="off"
                            onChange={
                              /* (e) => setGenderInput(e.target.value) */ genderChangeHandler
                            }
                            value={[genderInput === "Male" || "Male"]}
                          />
                          <label
                            className="btn btn-outline-success"
                            htmlFor="male"
                          >
                            Male
                          </label>
                        </div>

                        <div className="ms-2 ">
                          <input
                            type="radio"
                            className="btn-check"
                            name="gender"
                            id="female"
                            autoComplete="off"
                            onChange={
                              /* (e) => setGenderInput(e.target.value) */ genderChangeHandler
                            }
                            value={[genderInput === "Female" || "Female"]}
                          />
                          <label
                            className="btn btn-outline-info"
                            htmlFor="female"
                          >
                            Female
                          </label>
                        </div>
                      </InputGroup>
                      {genderPress && (
                        <Form.Control.Feedback type="invalid">
                          Please input your gender
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>

                  <Col sm={12} className="form-group mb-3">
                    <label htmlFor="inputdob" className="required">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="inputdob"
                      placeholder="Your Birthday"
                      className={
                        `form-control form-control-sm ` +
                        dobClasses +
                        dobClasses2
                      }
                      onChange={dobChangeHandler}
                      onBlur={dobBlurHandler}
                      value={enteredDob}
                      //minDate={new Date("1/1/2000")}
                    />
                    {dobInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Please select your birthdate.
                      </Form.Control.Feedback>
                    )}
                    {dobAgeError && (
                      <Form.Control.Feedback type="invalid">
                        Age must be older than 18 years old
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <Col sm={12} className="form-group mb-2">
                    <label htmlFor="inputabout" className="required">
                      About
                    </label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder="Desribe yourself, strength and goals"
                      className={aboutClasses}
                      onChange={aboutChangeHandler}
                      onBlur={aboutBlurHandler}
                      value={enteredAbout}
                    />
                    {aboutInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Tell us about yourself
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <hr className="my-4" />
                  <Heading content="Education" design="h4 mb-4" />

                  <Col sm={12} className="form-group mb-2">
                    <label htmlFor="inputSchool" className="required">
                      College
                    </label>

                    <Typeahead
                      inputProps={{ required: true }}
                      id="valid-styles-example"
                      size="small"
                      //style={{ appearence: "none" }}
                      placeholder="Choose the college you studied in"
                      className={collegeClasses}
                      onChange={collegeChangeHandler}
                      onInputChange={collegeChangeHandler}
                      onBlur={collegeBlurHandler}
                      options={universityList}
                      value={enteredCollege}
                    />
                    {collegeInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Please fill in your college
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <Col sm={12} className="form-group mb-2">
                    <label htmlFor="eduLevel" className="required">
                      Highest Education Level
                    </label>

                    <select
                      aria-label="Education Level"
                      className={highLevelClasses}
                      onChange={highLevelChangeHandler}
                      onBlur={highLevelBlurHandler}
                    >
                      <option value="">Please Choose One</option>
                      {eduList.map((eduList, key) => {
                        const enteredHighLevel = eduList.child_name;
                        return (
                          <option
                            key={enteredHighLevel}
                            value={enteredHighLevel}
                          >
                            {enteredHighLevel}
                          </option>
                        );
                      })}
                    </select>

                    {highLevelInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        You must choose your education Level
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <Col sm={12} className="form-group mb-2">
                    <label htmlFor="studyField" className="required">
                      Field of Study
                    </label>

                    <Typeahead
                      inputProps={{ required: true }}
                      id="valid-styles-example"
                      size="small"
                      placeholder="Choose a Field of Study"
                      className={studyFieldClasses}
                      onChange={studyFieldChangeHandler}
                      onInputChange={studyFieldChangeHandler}
                      onBlur={studyFieldBlurHandler}
                      options={studyList}
                      value={enteredStudyField}
                    />
                    {studyFieldInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Please fill in your field of study.
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <label htmlFor="eduYear" className="required mb-2">
                    Year
                  </label>
                  <Col sm={12} className="form-group mb-2 d-flex">
                    <span className="mx-auto">
                      <InputGroup className="mb-3">
                        <Col
                          className={
                            `text-center form-control form-control-sm ` +
                            yearClasses
                          }
                        >
                          <DatePicker
                            required
                            selected={startYear}
                            onChange={startYearChangeHandler}
                            showYearPicker
                            dateFormat="yyyy"
                            yearItemNumber={10}
                            minDate={new Date("01-01-1950")}
                            placeholderText="Year Start"
                            className="rounded text-center"
                          />
                        </Col>
                        <Col
                          className={
                            `text-center form-control form-control-sm ` +
                            yearClasses2
                          }
                        >
                          <DatePicker
                            selected={endYear}
                            onChange={
                              /* (date) => setEndDate(date) */ endYearChangeHandler
                            }
                            showYearPicker
                            dateFormat="yyyy"
                            yearItemNumber={10}
                            //maxDate={new Date().getTime}
                            placeholderText="Year End"
                            className="rounded text-center"
                          />
                        </Col>
                        {startYearPress && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-center"
                          >
                            Fill In start year
                          </Form.Control.Feedback>
                        )}
                        {endYearPress && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-center"
                          >
                            Fill In end year
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </span>
                  </Col>

                  <hr className="my-4" />

                  <Heading content="Working Experience" design="h4 mb-4" />
                  <label htmlFor="currentStatus" className="required mb-2">
                    Current Status
                  </label>
                  <Col
                    sm={12}
                    className={
                      `form-group mb-2 d-flex form-control form-control-sm ` +
                      statusClasses
                    }
                  >
                    <span className="mx-auto">
                      <InputGroup className="mb-3">
                        <div>
                          <input
                            type="radio"
                            className="btn-check"
                            name="status"
                            id="employedCS"
                            autoComplete="off"
                            onChange={currentStatusChangeHandler}
                            onBlur={currentStatusBlurHandler}
                            value={[
                              enteredCurrentStatus === "employee" || "employee",
                            ]}
                            checked={currentStatus === 2}
                            onClick={(e) => jobStatus(2)}
                          />
                          <label
                            className="btn btn-outline-success"
                            htmlFor="employedCS"
                          >
                            Employed
                          </label>
                        </div>

                        <div className="ms-2 ">
                          <input
                            type="radio"
                            className="btn-check"
                            name="status"
                            id="internCS"
                            autoComplete="off"
                            onChange={currentStatusChangeHandler}
                            onBlur={currentStatusBlurHandler}
                            value={[
                              enteredCurrentStatus === "intern" || "intern",
                            ]}
                            checked={currentStatus === 3}
                            onClick={(e) => jobStatus(3)}
                          />
                          <label
                            className="btn btn-outline-warning"
                            htmlFor="internCS"
                          >
                            Intern
                          </label>
                        </div>

                        <div className="ms-2 ">
                          <input
                            type="radio"
                            className="btn-check"
                            name="status"
                            id="unemployedCS"
                            autoComplete="off"
                            onChange={currentStatusChangeHandler}
                            onBlur={currentStatusBlurHandler}
                            value={[
                              enteredCurrentStatus === "Looking for a Job" ||
                                "Looking for a Job",
                            ]}
                            checked={currentStatus === 1}
                            onClick={(e) => jobStatus(1)}
                          />
                          <label
                            className="btn btn-outline-info"
                            htmlFor="unemployedCS"
                          >
                            Unemployed
                          </label>
                        </div>
                      </InputGroup>
                    </span>
                  </Col>
                  {currentStatusInputHasError && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-center"
                    >
                      Please choose your status
                    </Form.Control.Feedback>
                  )}
                  {/* employed */}
                  {currentStatus === 2 && (
                    <div>
                      <Col sm={12} className="form-group mb-2">
                        <label htmlFor="companyName" className="required">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className={
                            `form-control form-control-sm ` + companyClasses
                          }
                          id="companyName"
                          placeholder="Company Name"
                          value={companyName}
                          onChange={setCompanyName}
                          onBlur={companyNameBlurHandler}
                        />
                        {companyNameHasError && (
                          <Form.Control.Feedback type="invalid">
                            Please enter your company name
                          </Form.Control.Feedback>
                        )}
                      </Col>

                      <Col sm={12} className="form-group mb-2">
                        <label htmlFor="currentPos" className="required">
                          Current Position
                        </label>
                        <input
                          size="sm"
                          type="text"
                          className={
                            `form-control form-control-sm ` + positionClasses
                          }
                          id="currentPos"
                          placeholder="Current Position"
                          value={currentPosition}
                          onChange={setCurrentPosition}
                          onBlur={currentPositionBlurHandler}
                        />
                        {currentPositionHasError && (
                          <Form.Control.Feedback type="invalid">
                            Please enter your position
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </div>
                  )}
                  {/* internship */}
                  {currentStatus === 3 && (
                    <div>
                      <Col sm={12} className="form-group mb-2">
                        <label htmlFor="inputbgCourse" className="required">
                          Background of your course
                        </label>
                        {/* <Form.Control
                          as="textarea"
                          style={{ resize: "none" }}
                          rows={3}
                          placeholder="Tell us about background of your course"
                          className={
                            `form-control form-control-sm ` + bgCourseClasses
                          }
                          id="bgCourse"
                          value={bgCourse}
                          onChange={setBgCourse}
                          onBlur={bgCourseBlurHandler}
                        /> */}
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            placeholder: "Tell about your internship",
                            removePlugins: [
                              "Heading",
                              // // "Essentials",
                              // // "Italic",
                              // "Bold",
                              // "Link",
                              "imageUpload",
                              "EasyImage",
                              "ImageUpload",
                              "MediaEmbed",
                              "BlockQuote",
                              // // "List",
                              "Indent",
                              "Table",
                            ],
                          }}
                          data={bgCourse}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          /* className={
                            `form-control ` + bgCourseClasses
                          } */
                          onChange={setEditorChangeHandler}
                          onBlur={bgCourseBlurHandler}
                        />
                        {/* {!bgCourseHasError && (
                          <Form.Control.Feedback type="invalid">
                            Describe your internship
                          </Form.Control.Feedback>
                        )} */}
                      </Col>
                      <Col xs={12} sm={12} className="form-group mb-2">
                        <label htmlFor="inputStartIntern" className="required">
                          Start Internship
                        </label>
                        <input
                          type="date"
                          className={
                            `form-control form-control-sm ` + startInternClasses
                          }
                          id="startIntern"
                          value={startIntern}
                          onChange={setStartIntern}
                          onBlur={startInternBlurHandler}
                        />
                        {startInternHasError && (
                          <Form.Control.Feedback type="invalid">
                            Enter your internship start date
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col xs={12} sm={12} className="form-group mb-2">
                        <label htmlFor="inputStartIntern" className="required">
                          End Internship
                        </label>
                        <input
                          type="date"
                          className={
                            `form-control form-control-sm ` + endInternClasses
                          }
                          id="endIntern"
                          value={endIntern}
                          onChange={setEndIntern}
                          onBlur={endInternBlurHandler}
                        />
                        {endInternHasError && (
                          <Form.Control.Feedback type="invalid">
                            Enter your internship end date
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </div>
                  )}

                  <hr className="my-4" />
                  <Heading content="Skills & Experience" design="h4 mb-4" />
                  <Col sm={12} className="form-group mb-2">
                    <label htmlFor="inputskillexp" className="required">
                      Skills & Experience
                    </label>

                    <Typeahead
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      className={skillsClasses}
                      onChange={skillsChangeHandler}
                      onBlur={skillsBlurHandler}
                      options={skillList}
                      value={enteredSkills}
                      placeholder="Add a new skill:"
                      selected={enteredSkills}
                      newSelectionPrefix="Click this list to add suggested or a new skill: "
                      //allowNew
                    />
                    {skillsInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Select at least one skill
                      </Form.Control.Feedback>
                    )}
                  </Col>

                  <Col className="text-end my-4">
                    <button
                      type="submit"
                      className=" btn btn-primary"
                      disabled={!userBasicInfoFormIsValid}
                    >
                      Submit
                    </button>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default User;
