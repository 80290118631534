import { useState } from "react";

const useTypeHead = () => {
  const isNotArray = (value) => value.length !== 0;

  const [enteredValue, setEnteredValue] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  //validate valueis the function imported as parameter
  const valueIsValid = isNotArray(enteredValue);
  const hasError = !valueIsValid && isTouched;

  //value left as regular parameter
  const valueChangeHandler = (value) => {
    //unfeasable
    /* for (let i = 0; value.length > i; i++) {
      try {
        if (value[i].name) {
          value.push(value[i].name);
          setEnteredValue(value);
          console.log("this name " + value[i].name);
        }
      } catch (error) {
        console.log("Backtracking catch...");
      }
    }
     */
    if (value) {
      setEnteredValue(value);
    }

    console.log(value);
    //console.log(enteredValue);
  };

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue("");
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useTypeHead;
