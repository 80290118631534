import React from 'react';


function Suggestion() {
    return (
        <div>
            <p></p>
        </div>
    )
}

export default Suggestion;